import { useTheme } from "@emotion/react";
import { SVGProps } from "react";

const GiftIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
      <path
        fill={theme.system.primary[5]}
        d="M15.267 4.764c-.3-.207-1.135 0-1.346.12-1.8 1.054-4.724 3.63-4.271 6.31.507 2.99 10.819 5.833 10.819 5.833l1.37-.683c-.429-2.303-4.463-10.162-6.572-11.58Zm-1.575 6.25c-.04-.74.897-1.58 1.417-1.991.195-.152.44-.13.622.073.581.684 1.828 2.408 1.968 4.746 0 0-3.945-1.817-4.008-2.828Z"
      />
      <path
        fill={theme.system.primary[5]}
        d="M28.146 4.938c-.206-.127-1.038-.347-1.342-.148-2.138 1.383-6.32 9.162-6.782 11.454l1.357.711s10.354-2.65 10.926-5.633c.5-2.67-2.375-5.298-4.159-6.384Zm-3.941 8.883c.18-2.337 1.464-4.037 2.053-4.71a.435.435 0 0 1 .625-.06c.512.42 1.437 1.279 1.38 2.016-.077 1.009-4.058 2.754-4.058 2.754ZM19.97 24.205l.012-8.467-11.773-.025.918 8.47 10.842.022Z"
      />
      <path
        fill={theme.system.primary[5]}
        d="m32.557 24.224 1.18-8.462-11.777-.021-.012 8.465 10.609.018Zm-10.615 1.96-.015 8.464 9.167.018 1.182-8.464-10.334-.018Zm-1.976-.004L9.34 26.159l.921 8.47 9.689.016.016-8.463Z"
      />
    </svg>
  );
};

export default GiftIcon;
