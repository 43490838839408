import * as React from "react";
import { SVGProps } from "react";

const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" {...props}>
    <path d="M9.99984 18.3333C5.39734 18.3333 1.6665 14.6025 1.6665 9.99996C1.6665 5.39746 5.39734 1.66663 9.99984 1.66663C14.6023 1.66663 18.3332 5.39746 18.3332 9.99996C18.3332 14.6025 14.6023 18.3333 9.99984 18.3333ZM5.844 15.2133C7.02363 16.1564 8.48957 16.669 9.99984 16.6666C11.6415 16.6666 13.144 16.0733 14.3057 15.09C13.7629 14.5331 13.114 14.0907 12.3974 13.789C11.6807 13.4872 10.9108 13.3323 10.1332 13.3333C9.32699 13.3324 8.52942 13.499 7.79106 13.8226C7.05269 14.1463 6.38965 14.6198 5.844 15.2133ZM4.67984 14.0166C5.38022 13.2734 6.2254 12.6814 7.16329 12.2772C8.10118 11.8731 9.1119 11.6653 10.1332 11.6666C11.1179 11.6653 12.0931 11.8586 13.003 12.2353C13.9128 12.612 14.7392 13.1647 15.4348 13.8616C16.1482 12.8576 16.5695 11.6755 16.652 10.4466C16.7344 9.21762 16.4748 7.98986 15.9019 6.89951C15.329 5.80915 14.4653 4.89881 13.4065 4.2695C12.3477 3.64018 11.1352 3.31649 9.90368 3.33434C8.6721 3.35218 7.46955 3.71086 6.42944 4.37059C5.38932 5.03032 4.5523 5.96529 4.01125 7.07179C3.47019 8.17829 3.24625 9.41305 3.36427 10.6391C3.48229 11.8651 3.93765 13.0345 4.67984 14.0175V14.0166ZM9.99984 10.8333C9.11578 10.8333 8.26794 10.4821 7.64281 9.85698C7.01769 9.23186 6.6665 8.38401 6.6665 7.49996C6.6665 6.6159 7.01769 5.76806 7.64281 5.14294C8.26794 4.51782 9.11578 4.16663 9.99984 4.16663C10.8839 4.16663 11.7317 4.51782 12.3569 5.14294C12.982 5.76806 13.3332 6.6159 13.3332 7.49996C13.3332 8.38401 12.982 9.23186 12.3569 9.85698C11.7317 10.4821 10.8839 10.8333 9.99984 10.8333ZM9.99984 9.16663C10.4419 9.16663 10.8658 8.99103 11.1783 8.67847C11.4909 8.36591 11.6665 7.94199 11.6665 7.49996C11.6665 7.05793 11.4909 6.63401 11.1783 6.32145C10.8658 6.00889 10.4419 5.83329 9.99984 5.83329C9.55781 5.83329 9.13389 6.00889 8.82133 6.32145C8.50877 6.63401 8.33317 7.05793 8.33317 7.49996C8.33317 7.94199 8.50877 8.36591 8.82133 8.67847C9.13389 8.99103 9.55781 9.16663 9.99984 9.16663Z" />
  </svg>
);

export default UserIcon;
