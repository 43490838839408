import styled from "@emotion/styled";
import React, { useCallback } from "react";
import { memo } from "react";
import { Modal } from "../../../../components/Modal";
import useUIKit from "../../../../hooks/useUIKit";
import { LANGUAGES } from "../../../../types/enum";
import { ILocale } from "../../../../types";
import useLocalization from "../../../../hooks/useLocalization";

const Title = styled.div`
  color: ${({ theme }) => theme.system.gray[9]};
  font-size: 14px;
  font-weight: 500;
`;

const Group = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const Block = styled.div<{ actived?: boolean }>`
  cursor: pointer;
  height: 40px;
  padding-right: 16px;
  line-height: 40px;
  /* border-radius: 4px; */
  &[data-actived],
  &:hover {
    color: ${({ theme }) => theme.system.primary[6]};
  }
`;

const I18nModal = memo(() => {
  const { locale, setLocale, i18nModal, setI18nModal } = useUIKit();
  const { t } = useLocalization();
  const onChoose = useCallback((locale: ILocale) => {
    setLocale(locale);
    setI18nModal(false);
  }, []);

  return (
    <Modal isOpen={i18nModal} renderTitle={t("Language and Region")} onCancel={setI18nModal} width={480} zIndex={1001}>
      <Title>{t("Choose a language and region")}</Title>
      <Group>
        {Object.keys(LANGUAGES)
          .filter((p) => p === "en" || p === "zh-CN" || p === "ko" || p === "vi" || p === "pt" || p === "es")
          .map((p) => (
            <Block key={p} data-actived={p === locale ? "" : undefined} onClick={onChoose.bind(null, p as ILocale)}>
              {LANGUAGES[p as ILocale]}
            </Block>
          ))}
      </Group>
    </Modal>
  );
});

export default I18nModal;
