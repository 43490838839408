import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.945.844H2.642A2.642 2.642 0 0 0 0 3.486v3.303A2.642 2.642 0 0 0 2.642 9.43h3.303a2.642 2.642 0 0 0 2.642-2.642V3.486A2.642 2.642 0 0 0 5.945.844ZM5.945 11.412H2.642A2.642 2.642 0 0 0 0 14.055v3.303A2.642 2.642 0 0 0 2.642 20h3.303a2.642 2.642 0 0 0 2.642-2.642v-3.303a2.642 2.642 0 0 0-2.642-2.643ZM16.514 11.412H13.21a2.642 2.642 0 0 0-2.642 2.643v3.303A2.642 2.642 0 0 0 13.21 20h3.303a2.642 2.642 0 0 0 2.642-2.642v-3.303a2.642 2.642 0 0 0-2.642-2.643ZM16.73.934l2.336 2.336c1.245 1.245 1.245 2.49 0 3.736L16.73 9.342c-1.245 1.245-2.49 1.245-3.736 0l-2.336-2.336c-1.245-1.245-1.245-2.49 0-3.736L12.994.934c1.245-1.245 2.49-1.245 3.736 0Z" />
  </svg>
);

export default SvgComponent;
