import styled from "@emotion/styled";
import { memo } from "react";
import useUIKit from "../../../../hooks/useUIKit";
import { LANGUAGES } from "../../../../types/enum";
import I18nIcon from "../svgs/I18nIcon";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.system.gray[9]};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  :hover {
    color: ${({ theme }) => theme.system.primary[6]};
  }

  &[data-drawer] {
    width: 100%;
    height: 64px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 600;
    & > svg {
      margin-right: 16px;
    }
    :hover {
      color: inherit;
      background: ${({ theme }) => theme.system.gray[1]};
    }
  }
`;

type TProps = { isDrawer?: boolean };

const I18n = memo<TProps>(({ isDrawer }) => {
  const { locale, setI18nModal } = useUIKit();

  return (
    <Wrapper onClick={setI18nModal.bind(null, true)} data-drawer={isDrawer ? "" : undefined}>
      {isDrawer ? "" : <I18nIcon />} {isDrawer ? LANGUAGES[locale] : ""}
    </Wrapper>
  );
});

export default I18n;
