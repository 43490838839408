import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { memo } from "react";
import { MenuDefaultIcon } from "../../../../svgs";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import SubNavItem from "./SubNavItem";
import { ClassNames } from "@emotion/react";
import { ExternalLink } from "../../../../components/ExternalLink";
import { useCopyClipboard, useUIKit } from "../../../../hooks";
import useLocalization from "../../../../hooks/useLocalization";
import { DOMAIN } from "../../../../config";
import SubAccountIcon from "../svgs/SubAccountIcon";
import LogoutIcon from "../svgs/LogoutIcon";
import APIKeyIcon from "../svgs/APIKeyIcon";
import UserIcon from "../svgs/UserIcon";
import BalanceIcon from "../svgs/BalanceIcon";
import DepositIcon from "../svgs/DepositIcon";
import WithdrawIcon from "../svgs/WithdrawIcon";
import TransferIcon from "../svgs/TransferIcon";
import HistoryIcon from "../svgs/HistoryIcon";
import DashboardIcon from "../svgs/DashboardIcon";
import SecurityIcon from "../svgs/SecurityIcon";
import { ISystem } from "../../type";
import { ChevronDownIcon, CopyIcon } from "../../../../icons";
import ReferralIcon from "../svgs/ReferralIcon";
import { Button } from "../../../../components/Button";
import SwitchIcon from "../svgs/SwitchIcon";
import { shortenAddress } from "@oxfun/sdk";
import { toast } from "react-toastify";
import { POPOVER } from "../../../../components/Toast";
import LoginMobileAppIcon from "../svgs/LoginMobileAppIcon";
import YieldIcon from "../svgs/YieldIcon";
import { Image } from "../../../../components/Image";
import ClaimRewardIcon from "../svgs/ClaimRewardIcon";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  &[data-drawer] {
    flex-direction: column;
    height: fit-content;
  }

  &:not([data-drawer]) {
    & > .oxfun-external-link {
      height: 100%;
    }
  }

  /* :not([data-drawer]) {
    margin-right: 16px;

    ::after {
      content: "";
      width: 1px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: ${({ theme }) => theme.system.blueGray[3]};
    }
  } */
`;
const Btns = styled.div`
  display: flex;
  align-items: center;

  &[data-drawer] {
    display: none;
  }
`;
const DropdownWrapper = styled(Dropdown)`
  padding: 0 16px;
  white-space: nowrap;
  &[data-icon] {
    padding: 0 8px;
  }

  @media screen and (max-width: 1440px) {
    padding: 0 12px;
  }

  @media screen and (max-width: 1280px) {
    padding: 0 10px;
  }
`;

const DrawerBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.system.gray[9]};
  overflow: hidden;
`;

const DrawerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  padding: 0 16px;
  :hover,
  &[data-expand] {
    background: ${({ theme }) => theme.system.blueGray[3]};
    & > svg {
      color: ${({ theme }) => theme.system.primary[6]};
    }
  }
  & > div {
    display: grid;
    gap: 16px;
    grid-auto-flow: column;
    align-items: center;
  }
  & > svg {
    transition: transform 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: ${({ theme }) => theme.system.gray[9]};
    font-size: 24px;
  }

  &[data-expand] > svg {
    transform: rotateX(180deg);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

const DrawerOverlayWrapper = styled.div`
  width: 100%;
  transition: max-height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  max-height: 0;
  &[data-expand] {
    max-height: 1000px;
  }
`;

const DIV = styled.div`
  width: calc(100% - 32px);
  margin: 8px 16px;
  height: 1px;
  background: ${({ theme }) => theme.system.gray[2]};
`;

const AccountInfo = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: auto 1fr;
  padding: 8px 16px;
  margin-bottom: 12px;

  & > div:first-of-type {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: ${({ theme }) => theme.system.primary[1]};
    color: ${({ theme }) => theme.system.primary[5]};
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 16px;
      font-weight: 500;
    }

    span {
      width: fit-content;
      display: grid;
      gap: 4px;
      grid-auto-flow: column;
      color: ${({ theme }) => theme.system.gray[6]};
      align-items: center;

      svg {
        cursor: pointer;
        :hover {
          color: ${({ theme }) => theme.system.gray[9]};
        }
      }
    }
  }

  &[data-drawer] {
    width: 100%;
  }
`;

const Claim = styled.div`
  padding: 0 16px;
  margin-bottom: 8px;
  & > div {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.system.primary[5]};
    background: linear-gradient(90deg, rgba(203, 251, 124, 0.1) 0%, rgba(203, 251, 124, 0) 100%);
    :hover {
      cursor: pointer;
    }
  }

  &[data-drawer] {
    width: 100%;

    & > div {
      padding: 12px 16px;
      font-size: 16px;
    }
  }
`;

type TProps = { isDrawer?: boolean };

const UserNav = memo<TProps>(({ isDrawer }) => {
  const { t } = useLocalization();

  const { fetchLogout, setSubAccountOpen, userInfo, setRewardsModal, setTransferModal } = useUIKit();
  const [_, staticCopy] = useCopyClipboard();

  const USER_NAV: ISystem["Nav"][] = [
    // {
    //   symbol: "Buy OX",
    //   text: t("Buy OX"),
    //   path: DOMAIN?.UI + "/buy-ox"
    // },
    {
      symbol: "Wallet & Order",
      text: t("Wallet & Order"),
      path: DOMAIN?.UI + "/wallet/deposit",
      subNav: [
        {
          symbol: "Balance",
          text: t("Balance"),
          icon: <BalanceIcon />,
          path: DOMAIN?.UI + "/account"
        },
        {
          symbol: "Deposit",
          text: t("Deposit"),
          icon: <DepositIcon />,
          path: DOMAIN?.UI + "/wallet/deposit"
        },
        {
          symbol: "Withdrawal",
          text: t("Withdrawal"),
          icon: <WithdrawIcon />,
          path: DOMAIN?.UI + "/wallet/withdraw"
        },
        {
          symbol: "Transfer",
          text: t("Transfer"),
          icon: <TransferIcon />,
          // path: DOMAIN?.UI + "/account",
          actions: () => {
            setTransferModal(true);
          }
        },
        // { symbol: "Yield", text: t("Yield"), icon: <YieldIcon />, path: DOMAIN?.UI + "/yield" },
        {
          symbol: "History",
          text: t("History"),
          icon: <HistoryIcon />,
          path: DOMAIN?.UI + "/user/home/walletManagement/history"
        }
      ]
    },
    {
      symbol: "ACCOUNT",
      text: t("Dashboard"),
      icon: <UserIcon />,
      path: DOMAIN?.UI + "/account",
      subNav: [
        {
          symbol: "Dashboard",
          text: t("Dashboard"),
          icon: <DashboardIcon />,
          path: DOMAIN?.UI + "/account"
        },
        {
          symbol: "Referral",
          text: t("Referral"),
          icon: <ReferralIcon />,
          path: DOMAIN?.UI + "/referral"
        },
        {
          symbol: "Security",
          text: t("Security"),
          icon: <SecurityIcon />,
          path: DOMAIN?.UI + "/user/home/security"
        },
        {
          symbol: "Sub Account",
          text: t("Sub Account"),
          icon: <SubAccountIcon />,
          path: DOMAIN?.UI + "/account?type=subaccount"
        },
        {
          symbol: "API Key",
          text: t("API Key"),
          icon: <APIKeyIcon />,
          path: DOMAIN?.UI + "/user/home/apiManagement"
        }
      ]
    }
  ];

  const [isUnfold, setUnfold] = useState<Record<string, boolean | undefined>>({});

  const [isDropdownOpen, setDropdownOpen] = useState<Record<string, boolean | undefined>>({});

  const nickName = useMemo(() => {
    const name = userInfo?.nickName;
    if (name) {
      if (name.length > 20) {
        return shortenAddress(name);
      }
      return name;
    }
    return "";
  }, [userInfo?.nickName]);

  return (
    <Wrapper data-drawer={isDrawer ? "" : undefined}>
      <Btns data-drawer={isDrawer ? "" : undefined}>
        <ExternalLink href={DOMAIN?.UI + "/buy-ox"}>
          <Button size={isDrawer ? "middle" : "extra-small"} colorScheme="primary">
            {t("Buy OX")}
          </Button>
        </ExternalLink>
      </Btns>
      {!isDrawer ? (
        <ClassNames>
          {({ css, theme }) =>
            USER_NAV.map((p) => {
              if (p.symbol === "ACCOUNT") {
                return (
                  <ExternalLink href={p.path} key={p.symbol}>
                    <DropdownWrapper
                      // arrowIcon={p.subNav?.length ? undefined : null}
                      data-icon=""
                      arrowIcon={null}
                      isOpen={isDropdownOpen[p.symbol]}
                      onOpenChange={(e) => {
                        setDropdownOpen((r) => ({ ...r, [p.symbol]: e }));
                      }}
                      placement="bottom-right"
                      overlay={
                        p.subNav?.length ? (
                          <React.Fragment>
                            <AccountInfo>
                              <div>
                                <Image
                                  size={48}
                                  src={
                                    userInfo?.profileUrl
                                      ? "https://" + userInfo.profileUrl
                                      : "/account/images/account-default.png"
                                  }
                                />
                              </div>
                              <div>
                                <p>{nickName}</p>
                                <span>
                                  {shortenAddress(userInfo?.publicAddress)}
                                  <CopyIcon
                                    onClick={() => {
                                      if (userInfo?.publicAddress) {
                                        staticCopy(userInfo?.publicAddress, () => {
                                          toast.success(t("Copied!"), {
                                            containerId: POPOVER.MESSAGE,
                                            toastId: "copyId"
                                          });
                                        });
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </AccountInfo>

                            <Claim>
                              <div
                                onClick={() => {
                                  setRewardsModal(true);
                                  setDropdownOpen((o) => ({ ...o, [p.symbol]: false }));
                                }}
                              >
                                <span>{t("Claim Rewards")}</span>
                                <ClaimRewardIcon />
                              </div>
                            </Claim>
                            <SubNavItem
                              path={undefined}
                              symbol={"Switch Sub Account"}
                              text={t("Switch Sub Account")}
                              icon={<SwitchIcon />}
                              onClick={() => {
                                setSubAccountOpen?.(true);
                                setDropdownOpen((o) => ({ ...o, [p.symbol]: false }));
                              }}
                            />
                            <DIV />
                            {p.subNav.map((r) => (
                              <SubNavItem
                                key={r.symbol}
                                {...r}
                                onClick={() => {
                                  setDropdownOpen((o) => ({ ...o, [p.symbol]: false }));
                                }}
                              />
                            ))}
                            <DIV />
                            <SubNavItem
                              path={undefined}
                              symbol={"Log out"}
                              text={t("Log out")}
                              icon={<LogoutIcon />}
                              onClick={() => {
                                fetchLogout();
                                setDropdownOpen((o) => ({ ...o, [p.symbol]: false }));
                              }}
                            />
                          </React.Fragment>
                        ) : undefined
                      }
                      overlayClassName={css({
                        ".oxfun-dropdown-container": {
                          padding: "12px 0",
                          boxShadow: "none"
                        }
                      })}
                    >
                      {p.icon || <MenuDefaultIcon />}
                    </DropdownWrapper>
                  </ExternalLink>
                );
              } else {
                return (
                  <ExternalLink href={p.path} key={p.symbol}>
                    <DropdownWrapper
                      arrowIcon={p.subNav?.length ? undefined : null}
                      isOpen={isDropdownOpen[p.symbol]}
                      onOpenChange={(e) => {
                        setDropdownOpen((r) => ({ ...r, [p.symbol]: e }));
                      }}
                      overlay={
                        p.subNav?.length
                          ? p.subNav.map((r) => (
                              <SubNavItem
                                key={r.symbol}
                                {...r}
                                onClick={() => {
                                  r.actions?.();
                                  setDropdownOpen((o) => ({ ...o, [p.symbol]: false }));
                                }}
                              />
                            ))
                          : undefined
                      }
                      overlayClassName={css({
                        ".oxfun-dropdown-container": {
                          padding: "12px 0",
                          boxShadow: "none"
                        }
                      })}
                    >
                      {p.text}
                    </DropdownWrapper>
                  </ExternalLink>
                );
              }
            })
          }
        </ClassNames>
      ) : (
        <React.Fragment>
          <AccountInfo data-drawer="">
            <div>
              {userInfo?.accountName === userInfo?.publicAddress
                ? userInfo?.publicAddress.slice(-2)
                : userInfo?.accountName.slice(2)}
            </div>
            <div>
              <p>
                {userInfo?.accountName === userInfo?.publicAddress
                  ? userInfo?.publicAddress.slice(-2)
                  : userInfo?.accountName}
              </p>
              <span>
                {shortenAddress(userInfo?.publicAddress)}
                <CopyIcon
                  onClick={() => {
                    if (userInfo?.publicAddress) {
                      staticCopy(userInfo?.publicAddress, () => {
                        toast.success(t("Copied!"), {
                          containerId: POPOVER.MESSAGE,
                          toastId: "copyId"
                        });
                      });
                    }
                  }}
                />
              </span>
            </div>
          </AccountInfo>

          <Claim data-drawer="">
            <div
              onClick={() => {
                setRewardsModal(true);
              }}
            >
              <span>{t("Claim Rewards")}</span>
              <ClaimRewardIcon />
            </div>
          </Claim>
          <SubNavItem
            path={undefined}
            symbol={"Switch Sub Account"}
            text={t("Switch Sub Account")}
            icon={<SwitchIcon />}
            isDrawer
            isUser
            onClick={() => {
              setSubAccountOpen?.(true);
            }}
          />
          <DIV />

          {USER_NAV.reverse().map((p) => {
            if (p.symbol === "ACCOUNT") {
              return (
                <DrawerBlock key={p.symbol}>
                  {!!p.subNav?.length && (
                    <DrawerOverlayWrapper data-expand={""}>
                      {p.subNav.map((r) => (
                        <SubNavItem key={r.symbol} {...r} isDrawer isUser />
                      ))}
                    </DrawerOverlayWrapper>
                  )}
                  <DIV />
                </DrawerBlock>
              );
            } else {
              return (
                <DrawerBlock key={p.symbol}>
                  <DrawerItem
                    onClick={() => {
                      setUnfold((e) => ({
                        ...e,
                        [p.symbol]: !e[p.symbol]
                      }));
                    }}
                    data-expand={isUnfold[p.symbol] ? "" : undefined}
                  >
                    <div>
                      {/* {p.icon || <MenuDefaultIcon />} */}
                      <span>{p.text}</span>
                    </div>
                    <ChevronDownIcon />
                  </DrawerItem>
                  {!!p.subNav?.length && (
                    <DrawerOverlayWrapper data-expand={isUnfold[p.symbol] ? "" : undefined}>
                      {p.subNav.map((r) => (
                        <SubNavItem
                          key={r.symbol}
                          {...r}
                          isDrawer
                          onClick={() => {
                            r.actions?.();
                          }}
                        />
                      ))}
                    </DrawerOverlayWrapper>
                  )}
                </DrawerBlock>
              );
            }
          })}

          <DIV />
          <SubNavItem
            path={undefined}
            symbol={"Log out"}
            text={t("Log out")}
            icon={<LogoutIcon />}
            isDrawer
            isUser
            onClick={() => {
              fetchLogout();
            }}
          />
        </React.Fragment>
      )}
    </Wrapper>
  );
});

export default UserNav;
