import styled from "@emotion/styled";
import React, { useState } from "react";
import { memo } from "react";
import Button from "../../../../components/Button/Button";
import Drawer from "../../../../components/Drawer/Drawer";
import useUIKit from "../../../../hooks/useUIKit";
import UserNav from "./UserNav";
import { CloseIcon } from "../../../../icons";
import UserIcon from "../svgs/UserIcon";

const ButtonWrapper = styled(Button)`
  padding: 0;
  font-size: 24px;
`;

const DrawerWrapper = styled(Drawer)`
  .oxfun-drawer-container {
    display: flex;
    flex-direction: column;
    &[data-placement="right"] {
      width: 375px;

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }
`;

const DrawerHeader = styled.div`
  height: 64px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 16px;
`;

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  scrollbar-width: 0;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background: ${({ theme }) => theme.system.gray[2]};
`;

type TProps = {};

const UserNavDrawer = memo<TProps>(() => {
  const { user } = useUIKit();
  const [isOpen, setOpen] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <React.Fragment>
      <ButtonWrapper onClick={setOpen.bind(null, true)} colorScheme="default" variant="text">
        <UserIcon />
      </ButtonWrapper>
      <DrawerWrapper isOpen={isOpen} onCancel={setOpen} placement="right">
        <DrawerHeader>
          <ButtonWrapper colorScheme="default" variant="text" onClick={setOpen.bind(null, false)}>
            <CloseIcon />
          </ButtonWrapper>
        </DrawerHeader>

        <Line />

        <Container>
          <UserNav isDrawer />
        </Container>
      </DrawerWrapper>
    </React.Fragment>
  );
});

export default UserNavDrawer;
