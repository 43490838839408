import * as React from "react";
import { SVGProps } from "react";

const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M13.375 10.0417L17.5 14.1667L13.375 18.2917L12.1967 17.1133L14.31 14.9992L3.33333 15V13.3333H14.31L12.1967 11.22L13.375 10.0417ZM6.625 1.70834L7.80333 2.88668L5.69 5.00001H16.6667V6.66668H5.69L7.80333 8.78001L6.625 9.95834L2.5 5.83334L6.625 1.70834Z"
      fill="white"
    />
  </svg>
);

export default UserIcon;
