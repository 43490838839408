import { useTheme } from "@emotion/react";
import { SVGProps } from "react";

const CongratulationsIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={110} height={100} fill="none" {...props}>
      <circle cx={55} cy={57} r={40} fill="url(#a)" />
      <path
        fill={theme.system.primary[5]}
        d="M46.893 39.795 32.575 77.532 70.314 63.22 46.891 39.795h.002Zm31.425 25.142-53.4 20.255 20.255-53.4 33.145 33.145ZM80.26 23.54l3.143 3.145L64.545 45.54l-3.142-3.142L80.26 23.54Zm-8.647 29.075-3.05-3.233c6.68-6.312 14.032-7.474 21.52-3.325l-2.155 3.888c-5.742-3.182-11-2.35-16.315 2.667v.003Zm-18.318-18.32 3.233 3.05c6.31-6.677 7.475-14.032 3.325-21.517l-3.888 2.155c3.183 5.74 2.35 11-2.67 16.312Zm32.16-12.405-3.674-1.815 3.54-2.065 1.814-3.68 2.068 3.54 3.68 1.82-3.542 2.065-1.816 3.678-2.07-3.543Zm6.233 34.22-3.625-1.928 3.625-1.93 1.927-3.625 1.93 3.625 3.626 1.93-3.626 1.928-1.93 3.627-1.927-3.625v-.002ZM49.363 13.585l-3.73-1.825 3.732-1.825 1.825-3.733 1.826 3.73 3.73 1.825-3.733 1.825-1.825 3.73-1.825-3.727Z"
      />
      <path
        fill={theme.system.primary[5]}
        d="m43.607 38.288 4.43.362-3.08 37.5-4.43-.362 3.08-37.5Zm10.075 7.957 4.44.163-.902 24.65-4.443-.165.905-24.65v.002Z"
      />
      <defs>
        <linearGradient id="a" x1={55} x2={55} y1={17} y2={97} gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.system.primary[5]} stopOpacity={0} />
          <stop offset={1} stopColor={theme.system.primary[5]} stopOpacity={0.1} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CongratulationsIcon;
