import { isBrowser } from "../utils/dom";

export const __DEV__ = process.env.NODE_ENV !== "production";

export const __TEST__ = process.env.NODE_ENV === "test";

export const DOMAINS: Record<
  string,
  { UI: string; API: string; WSS: string; ADMIN?: string; CDN?: string; SA?: string; SA_AB?: string }
> = {
  PROD: {
    UI: "https://ox.fun",
    API: "https://api.ox.fun",
    WSS: "wss://api.ox.fun",
    ADMIN: "https://cfa.ox.fun",
    CDN: "https://cdn.ox.fun"
  },
  DEV: {
    UI: "https://stg.ox.fun",
    API: "https://stgapi.ox.fun",
    WSS: "wss://stgapi.ox.fun",
    ADMIN: "https://stgcfa.ox.fun",
    CDN: "https://stgcdn.ox.fun"
  }
};

export type TEnvironment = keyof typeof DOMAINS;

export const ENVIRONMENT = (process.env.REACT_APP_ENVIRONMENT ??
  process.env.NEXT_PUBLIC_ENVIRONMENT ??
  process.env.STORYBOOK_ENV) as TEnvironment | undefined;

export const DOMAIN = ENVIRONMENT ? DOMAINS[ENVIRONMENT] : __DEV__ ? DOMAINS.DURIAN : null;

export const BASE_URL = DOMAIN?.UI;

export const WSS_URL = DOMAIN?.WSS + "/v2/websocket";

export const REQUEST_URLS = {
  ACCOUNTS: "/v2/account/protected/accounts/normal/list",
  LOGOUT: "/v2/account/auth/trading/logout",
  GEETEST_VALIDATE: "/v2/account/auth/validate",
  GEETEST_INIT: "/v2/account/init",
  LOGIN: "/v2/account/auth/trading/login",
  TOKEN_CHECK: "/v2/account/protected/check/token",
  SWITCH_ACCOUT: "/v2/account/protected/trading/switch/account",
  GET_RECAPTCHA_KEY: "/v2/account/auth/recaptcha/get-site-key?x-device-type=web",
  GET_BANNER: "/v2/account/public/banner",
  GET_COIN_BALACE: "/v2/account/protected/deposit/balance/",
  LEND: "/v2/mortgage/protected/lendingHistory/deposit",
  REDEEM: "/v2/mortgage/protected/lendingHistory/withdraw",
  LEND_POOL: "/v2/mortgage/public/lending/pools",
  REDEEM_BALANCE: "/v2/mortgage/protected/lending/balance/",
  UPLOAD_FILE: "/v2/dbworker/protected/upload/picture/encrypt",
  READ_FILE: "/v2/dbworker/protected/download/encrypt/picture/?key=",
  GET_TOKEN_TIMES: "/v2/account/public/logins/tokens/times",
  VALIDATE_ADDRESS: "/v2/account/auth/validate/bind/",
  GET_ADDRESS_NONCE: "/v2/account/auth/metamask/users",
  GET_SOLANA_NONCE: "/v2/account/auth/phantom/user",
  WALLET_AUTH: "/v2/account/auth/metamask",
  SOLANA_AUTH: "/v2/account/auth/phantom",
  USER_INFO: "/v2/account/protected/current/login",
  GET_QRCODE: "/v2/account/protected/generate/qr/code",
  GET_QRCODE_STATUS: "/v2/account/protected/qr/status",
  CONFIRM_QRCODE_LOGIN: "/v2/account/protected/confirm/qr/login",
  GET_TRANSFER_COINS: "/v2/markets/protected/coin/claims",
  GET_TRANSFER_ACCOUNTS: "/v2/account/protected/transfers/accounts/combos",
  GET_TRANSFER_BALANCE: "/v2/account/protected/balances?needDisplay=false",
  SUBMIT_TRANSFER: "/v2/account/protected/transfers/transfer",
  GET_DECIMALS: "/v2/markets/public/instruments/coins/accuracys/all",
  GET_COIN_ICONS: "/v2/markets/public/get/instrument/icons?code=",
  CHECK_REWARDS: "/v2/lending/protected/airdrop/check/claim",
  GET_REWARDS: "/v2/lending/protected/airdrop/claim",
  CAPTCHA_ID: "/v2/account/auth/geetest4/get-captcha-id?x-device-type=web"
};

export const __UIKIT__ =
  isBrowser &&
  (window.location.href.includes("6006") ||
    window.location.href.includes("uikit") ||
    window.location.href.includes("path=/story") ||
    window.location.href.includes("iframe.html"));
