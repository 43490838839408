import styled from "@emotion/styled";
import React, { memo } from "react";
import { ExternalLink, IExternalLinkProps } from "../../../../components/ExternalLink";
import { Text } from "../../../../components/Text";
import { MenuDefaultIcon } from "../../../../svgs";
import { ISystem } from "../../type";

const Wrapper = styled(ExternalLink)`
  width: 100%;
  display: flex;
  align-items: center;
  /* border-radius: 12px; */
  padding: 0 16px;
  height: 48px;
  min-width: 236px;
  :hover {
    background: ${({ theme }) => theme.system.gray[1]};
  }

  &:not([data-user])[data-drawer] {
    border-radius: 0;
    padding-left: 52px;
    height: 56px;
    /* :hover {
      background: none;
      .oxfun-text {
        color: ${({ theme }) => theme.system.primary[6]};
      }
    } */
  }

  & > svg {
    margin-right: 16px;
  }

  &[data-user] {
    height: 56px;
  }
`;

const Icon = styled.div`
  width: 52px;
  height: 52px;
  /* border-radius: 16px; */
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.system.blueGray[1]};
  backdrop-filter: blur(8px);
`;

type TProps = ISystem["SubNav"] &
  IExternalLinkProps & {
    isDrawer?: boolean;
    isUser?: boolean;
    isNav?: boolean;
  };

const SubNavItem = memo<TProps>(({ icon, text, path, target, isDrawer, isUser, ...props }) => {
  return !isDrawer ? (
    <Wrapper href={path} target={target} {...props}>
      {icon || <MenuDefaultIcon />}
      <Text fontSize={14} fontWeight={500}>
        {text}
      </Text>
    </Wrapper>
  ) : (
    <Wrapper href={path} target={target} data-drawer="" data-user={isUser ? "" : undefined} {...props}>
      {isUser ? icon || <MenuDefaultIcon /> : null}
      <Text fontSize={16} fontWeight={400}>
        {text}
      </Text>
    </Wrapper>
  );
});

export default SubNavItem;
