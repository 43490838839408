import styled from "@emotion/styled";
import React from "react";
import { memo } from "react";
import { ExternalLink } from "../../../../components/ExternalLink";
import { Button } from "../../../../components/Button";
import { useLocalization, useUIKit } from "../../../../hooks";
import { DOMAIN } from "../../../../config/constants";

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  gap: 8px;
  grid-auto-flow: column;
  margin-right: 8px;

  &[data-drawer] {
    padding: 0 16px;
    grid-auto-flow: row;
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
    .oxfun-btn {
      width: 100%;
      grid-row: 1;
      height: 40px;
      font-size: 14px;
    }

    a {
      width: 100%;
      grid-row: 2;
    }
  }
`;

type TProps = {
  isDrawer?: boolean;
  ConnectWallet?: React.ReactNode;
};
const Btns = memo<TProps>(({ isDrawer, ConnectWallet }) => {
  const { t } = useLocalization();
  const { user } = useUIKit();

  return (
    <Wrapper data-drawer={isDrawer ? "" : undefined}>
      <ExternalLink href={DOMAIN?.UI + "/buy-ox"}>
        <Button size="extra-small" colorScheme={isDrawer && !!user ? "primary" : "default"}>
          {t("Buy OX")}
        </Button>
      </ExternalLink>

      {!user && ConnectWallet}
    </Wrapper>
  );
});

export default Btns;
