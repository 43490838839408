import styled from "@emotion/styled";
import React, { memo, useEffect, useState } from "react";
import { Progress } from "../../../components/Progress";
import useLocalization from "../../../hooks/useLocalization";
import { SwitchAccountIcon } from "../../../svgs";

const Wrapper = styled.div`
  padding: 24px;
  svg {
    margin: 0 auto;
    display: block;
  }
  .${({ theme }) => theme.prefixCls}-line-progress {
    margin: 20px 0 8px;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & > span:last-of-type {
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.system.primary[6]};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

type TProps = {
  name?: string;
};

let timer: NodeJS.Timeout;

const SwitchAccoutLoading = memo<TProps>(({ name }) => {
  const [percent, setPercent] = useState(5);
  const { t } = useLocalization();
  useEffect(() => {
    timer = setInterval(() => {
      setPercent((p) => (p += 5));
    }, 50);
    return () => timer && clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (percent > 90) {
      clearInterval(timer);
    }
  }, [percent]);

  return (
    <Wrapper>
      <SwitchAccountIcon />
      <Progress percent={percent} />
      <p>
        <span>{t("Switching to")}</span>&nbsp;<span>{name}</span>
      </p>
    </Wrapper>
  );
});

export default SwitchAccoutLoading;
