import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const APIKeyIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="M15.833 10H4.167v6.667h11.666V10ZM4.167 8.333V1.667h11.666v6.666h.834a.833.833 0 0 1 .833.834V17.5a.833.833 0 0 1-.833.833H3.333A.833.833 0 0 1 2.5 17.5V9.167a.833.833 0 0 1 .833-.834h.834Zm1.666 0h8.334v-5H5.833v5ZM7.5 5h1.667v1.667H7.5V5Zm3.333 0H12.5v1.667h-1.667V5Z"
      />
    </svg>
  );
};

export default APIKeyIcon;
