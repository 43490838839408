import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={125} height={30} fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.318 12.59C1.074 19.853 5.732 26.782 12.6 28.332c.125.028.25.053.375.077 1.037.197 1.67-.947 1.073-1.83l-2.168-3.07c-3.943-1.596-6.385-5.818-5.63-10.227.7-4.091 4.351-6.399 6.089-7.042l4.929 7.093c.459.66.576 1.461.387 2.186a2.567 2.567 0 0 1-1.335 1.783 1.583 1.583 0 0 0-.053 1.875l.185.266a1.85 1.85 0 0 0 2.6.457l.312-.224c2.539-1.811 3.147-5.365 1.36-7.938L13.692 1.621c-5.614.695-10.037 5.093-11.374 10.97Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.183 17.395C29.426 10.132 24.768 3.203 17.9 1.653c-.124-.028-.25-.053-.374-.077-1.037-.197-1.67.948-1.074 1.83l2.169 3.07c3.943 1.596 6.384 5.818 5.63 10.227-.701 4.092-4.352 6.4-6.09 7.042l-4.835-6.959a2.594 2.594 0 0 1-.665-2.217 2.566 2.566 0 0 1 1.572-1.956 1.584 1.584 0 0 0 0-1.805l-.184-.265a1.85 1.85 0 0 0-2.6-.458l-.313.224c-2.538 1.812-3.147 5.366-1.36 7.938l7.032 10.117c5.614-.695 10.037-5.093 11.375-10.97Z"
    />
    <path d="M82.916 23.064h-3.23V7.382h8.875v2.724h-5.645v4.044h5.253v2.714h-5.253v6.2ZM89.915 23.064V7.382h3.283v12.936h6.28v2.746h-9.563ZM110.104 23.064h-8.917V7.382h8.917v2.724h-5.634v3.443h5.243v2.725h-5.243v4.044h5.634v2.746ZM124.996 23.064h-3.749l-3.601-5.932-3.601 5.932h-3.516l5.137-8.088-4.808-7.594h3.622l3.336 5.642 3.272-5.642h3.538l-4.861 7.776 5.231 7.906ZM42.575 23.057c-4.796 0-8.067-3.58-8.067-8.19 0-4.502 3.403-7.928 7.956-7.928 2.011 0 5.128.569 5.945 3.492l-2.451.8c-.283-.923-2.103-1.613-3.427-1.613-2.807 0-5.017 1.999-5.017 5.248 0 3.053 2.254 5.512 5.105 5.512 2.796 0 4.548-1.84 4.735-2.046.009-.01.024-.008.032.003l1.5 2.03c.005.008.006.017 0 .024-.164.187-2.419 2.668-6.311 2.668ZM70.108 23.057h-2.797V11.825h1.221c1.487 0 1.487-.006 1.487-.006v1.549c0-.809 1.284-1.8 3.996-1.8 2.508 0 3.95 1.264 3.95 4.157v7.332h-2.818V16.282c0-1.328-.355-2.229-1.776-2.229-1.51 0-2.641.922-3.04 2.23-.157.45-.223.942-.223 1.457v5.317ZM62.745 12.417h2.858v10.64h-2.858v-10.64ZM62.403 8.56a1.61 1.61 0 0 0 1.6 1.622c.884 0 1.6-.726 1.6-1.621a1.61 1.61 0 0 0-1.6-1.622c-.883 0-1.6.726-1.6 1.622Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.003 7.368c-.65 0-1.177.534-1.177 1.193 0 .658.527 1.192 1.177 1.192s1.177-.534 1.177-1.192c0-.66-.527-1.193-1.177-1.193Zm0 2.814a1.61 1.61 0 0 1-1.6-1.621 1.61 1.61 0 0 1 1.6-1.622c.884 0 1.6.726 1.6 1.622a1.61 1.61 0 0 1-1.6 1.62ZM50.13 17.312c0 3.135 2.425 5.342 5.506 5.342 3.104 0 5.527-2.208 5.527-5.342 0-3.113-2.424-5.342-5.527-5.342-3.08 0-5.506 2.228-5.506 5.342Zm1.908 0c0-2.234 1.63-3.824 3.598-3.824 1.988 0 3.62 1.589 3.62 3.824 0 2.26-1.633 3.825-3.62 3.825-1.967 0-3.598-1.567-3.598-3.825Zm3.598 5.745c-3.282 0-5.907-2.366-5.907-5.745 0-3.356 2.626-5.745 5.907-5.745 3.303 0 5.928 2.389 5.928 5.745 0 3.378-2.625 5.745-5.928 5.745Zm0-9.166c-1.736 0-3.197 1.399-3.197 3.421 0 2.044 1.46 3.422 3.197 3.422 1.757 0 3.218-1.378 3.218-3.422 0-2.022-1.46-3.42-3.218-3.42Z"
    />
    <path d="M49.729 17.312c0 3.378 2.626 5.745 5.907 5.745 3.303 0 5.928-2.366 5.928-5.745 0-3.356-2.625-5.745-5.928-5.745-3.282 0-5.907 2.389-5.907 5.745Zm2.71 0c0-2.022 1.46-3.42 3.197-3.42 1.757 0 3.218 1.398 3.218 3.42 0 2.044-1.46 3.422-3.218 3.422-1.736 0-3.197-1.378-3.197-3.422Z" />
  </svg>
);

export default SvgComponent;
