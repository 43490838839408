import styled from "@emotion/styled";
import { useSize } from "ahooks";
import React, { useState } from "react";
import { memo } from "react";
import Button from "../../../../components/Button/Button";
import Drawer from "../../../../components/Drawer/Drawer";
import { MenuOutlinedIcon } from "../../../../svgs";
import { isBrowser } from "../../../../utils/dom";
import Nav from "./Nav";
import Btns from "./Btns";
import I18n from "./I18n";
import Theme from "./Theme";
import useThemeMode from "../../../../hooks/useThemeMode";
import { CloseIcon } from "../../../../icons";
import Download from "./Download";
import SwitchBtn from "./SwitchBtn";

const ButtonWrapper = styled(Button)`
  padding: 0;
  font-size: 24px;
  margin-left: 8px;
`;

const DrawerWrapper = styled(Drawer)`
  .oxfun-drawer-container {
    display: flex;
    flex-direction: column;
    &[data-placement="right"] {
      width: 375px;

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }
`;

const DrawerHeader = styled.div`
  height: 64px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 16px;
`;

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  scrollbar-width: 0;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SwitchWrapper = styled.div`
  padding: 0 16px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background: ${({ theme }) => theme.system.gray[2]};
`;

type TProps = {
  ConnectWallet?: React.ReactNode;
};

const NavDrawer = memo<TProps>(({ ConnectWallet }) => {
  const body = useSize(isBrowser ? document.body : undefined);
  const [isOpen, setOpen] = useState(false);
  const { hardThemeMode, isShowTheme } = useThemeMode();

  return (
    <React.Fragment>
      <ButtonWrapper onClick={setOpen.bind(null, true)} colorScheme="default" variant="text">
        <MenuOutlinedIcon />
      </ButtonWrapper>
      <DrawerWrapper isOpen={isOpen} onCancel={setOpen} placement="right">
        <DrawerHeader>
          <ButtonWrapper colorScheme="default" variant="text" onClick={setOpen.bind(null, false)}>
            <CloseIcon />
          </ButtonWrapper>
        </DrawerHeader>

        <Btns ConnectWallet={ConnectWallet} isDrawer />

        {/* <SwitchWrapper>
          <SwitchBtn />
        </SwitchWrapper> */}
        <Line />

        <Container>
          {!!body?.width && body.width <= 1100 && (
            <>
              <Nav isDrawer /> <Line />
            </>
          )}
          {!hardThemeMode && isShowTheme && <Theme isDrawer />}
          <Download isDrawer />
          <I18n isDrawer />
        </Container>
      </DrawerWrapper>
    </React.Fragment>
  );
});

export default NavDrawer;
