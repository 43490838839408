import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const TransferIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="m13.375 10.042 4.125 4.125-4.125 4.125-1.178-1.179L14.31 15 3.333 15v-1.667H14.31l-2.113-2.113 1.178-1.178Zm-6.75-8.334 1.178 1.179L5.69 5h10.977v1.667H5.69L7.803 8.78 6.625 9.958 2.5 5.833l4.125-4.125Z"
      />
    </svg>
  );
};

export default TransferIcon;
