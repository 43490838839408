import React, { Fragment, useEffect, useState } from "react";
import { SystemLogo } from "../../../svgs";
import styled from "@emotion/styled";
import { useSize } from "ahooks";
import Theme from "./components/Theme";
import ExternalLink from "../../../components/ExternalLink/ExternalLink";
import useUIKit from "../../../hooks/useUIKit";
import { BASE_URL } from "../../../config";
import useThemeMode from "../../../hooks/useThemeMode";
import { isBrowser } from "../../../utils/dom";
import Nav from "./components/Nav";
import UserNav from "./components/UserNav";
import Btns from "./components/Btns";
import NavDrawer from "./components/NavDrawer";
import SubAccounts from "../../Common/SubAccounts/SubAccounts";
import { ISystem } from "../type";
import I18n from "./components/I18n";
import Banner from "./components/Banner";
import Download from "./components/Download";
import I18nModal from "./components/I18nModal";
import UserNavDrawer from "./components/UserNavDrawer";
import MobileQRCode from "./components/MobileQRCode";
import SwitchBtn from "./components/SwitchBtn";
import Transfer from "../../Common/Transfer/Transfer";
import RewardsModal from "./components/RewardsModal";

const Wrapper = styled.div`
  height: 64px;
  min-height: 64px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.system.mask};
  backdrop-filter: blur(20px);
  position: relative;
  .oxfun-dropdown-trigger {
    height: 100%;
  }
`;

const LeftNode = styled.div`
  display: grid;
  gap: 24px;
  grid-auto-flow: column;
  align-items: center;
`;

const Line = styled.div`
  height: 24px;
  width: 1px;
  background: ${({ theme }) => theme.system.gray[2]};
`;

const Fragmenting = styled.div`
  flex: 1;
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background: ${({ theme }) => theme.system.blueGray[5]};
  margin: 0 16px 0 8px;
`;

const Header: React.FC<ISystem["Header"]> = ({ ConnectWallet, ...props }) => {
  const { user, isSubAccountOpen, setSubAccountOpen } = useUIKit();

  const { hardThemeMode, isShowTheme } = useThemeMode();

  const [show, setShow] = useState(false);

  const body = useSize(show ? document.body : undefined);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fragment>
      <Banner />
      <Wrapper {...props}>
        <LeftNode>
          <ExternalLink href={BASE_URL} aria-label="OX.FUN">
            <SystemLogo />
          </ExternalLink>

          {/* {!!body?.width && body.width > 1024 && (
            <>
              <SwitchBtn />
              <Line />
            </>
          )} */}
        </LeftNode>

        {!!body?.width && body.width > 1024 && <Nav />}

        <Fragmenting />

        {!!body?.width && body.width > 768 && (
          <>
            {typeof user !== "undefined" ? user ? <UserNav /> : <Btns ConnectWallet={ConnectWallet} /> : null}
            <Divider />
            <Download />
            <I18n />
            {!hardThemeMode && isShowTheme && <Theme />}
          </>
        )}

        <React.Fragment>
          {typeof user !== "undefined"
            ? !user && !!body?.width && body.width > 400 && body.width <= 768 && <Btns ConnectWallet={ConnectWallet} />
            : null}
          {!!body?.width && body.width <= 768 && <UserNavDrawer />}
          {!!body?.width && body.width <= 1024 && <NavDrawer ConnectWallet={ConnectWallet} />}
        </React.Fragment>
      </Wrapper>
      <SubAccounts isOpen={isSubAccountOpen} onCancel={setSubAccountOpen} zIndex={1001} />
      <I18nModal />
      <MobileQRCode />
      <Transfer />
      <RewardsModal />
    </Fragment>
  );
};

Header.defaultProps = {};

export default Header;
