import * as React from "react";
import { SVGProps } from "react";
import { IThemeMode } from "../types";

const SvgComponent = (props: SVGProps<SVGSVGElement> & { themeMode?: IThemeMode }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={152} height={52} fill="none" {...props}>
      <path
        fill="#C1FF72"
        d="m49.088 16.864-11.16 11.16h-16l-3.24 3.24 6.936 6.936 6.936-6.936-1.64-1.64h4.528l1.64 1.64-11.464 11.464L14.176 31.28l6.448-6.456H36.6l7.96-7.96-4.6-4.6L42.224 10l6.864 6.864ZM14.648 24.824h3.712l-3.2 3.2h-1.84L2.16 16.864 9 10.032l2.264 2.264-4.576 4.568 7.96 7.96Z"
      />
      <path
        fill="#C8FB7C"
        d="M76.996 19.2h3.129v5.965h-3.129V19.2ZM88.925 19.2h3.13v5.965h-3.13V19.2ZM76.996 28.294h3.129v5.964h-3.129v-5.964ZM88.925 28.294h3.13v5.964h-3.13v-5.964ZM80.125 25.165h8.8v3.129h-8.8v-3.13ZM94.792 31.13h3.129v3.128h-3.129V31.13ZM62.329 19.2h8.8v3.129h-8.8V19.2ZM59.2 22.329h3.129v8.8H59.2v-8.8ZM71.13 22.329h3.128v8.8H71.13v-8.8ZM62.329 31.13h8.8v3.128h-8.8V31.13ZM118.559 19.2h3.129v11.93h-3.129V19.2ZM130.488 19.2h3.129v11.93h-3.129V19.2ZM121.688 31.13h8.8v3.128h-8.8V31.13ZM100.763 19.2h3.129v15.058h-3.129V19.2Z"
      />
      <path
        fill="#C8FB7C"
        d="M100.763 19.2h15.058v3.129h-15.058V19.2ZM100.763 25.262h11.929v3.13h-11.929v-3.13ZM136.355 19.2h3.129v15.058h-3.129V19.2ZM148.871 19.2H152v15.058h-3.129V19.2ZM139.484 22.036h3.129v3.129h-3.129v-3.13ZM142.613 25.165h3.129v3.129h-3.129v-3.13ZM145.742 28.294h3.129v3.128h-3.129v-3.128Z"
      />
    </svg>
  );
};

export default SvgComponent;
