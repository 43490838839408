import styled from "@emotion/styled";
import { useRequest } from "ahooks";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { formatToDecimalsSize } from "@oxfun/sdk";
import TransferIcon from "../../../icons/TransferIcon";
import { InputNumber } from "../../../components/InputNumber";
import { useAccount, useLocalization, useUIKit } from "../../../hooks";
import { ITransferAccount, ITransferBalance, ITransferParams } from "../../../types";
import {
  getCoinIcons,
  getDecimals,
  getTransferAccounts,
  getTransferBalance,
  getTransferCoins,
  submitTransfer
} from "../../../services/requests";
import { POPOVER, toast } from "../../../components/Toast";
import { Select } from "../../../components/Select";
import { Button } from "../../../components/Button";
import { Image } from "../../../components/Image";
import { Modal } from "../../../components/Modal";
import { DOMAIN } from "../../../config";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button {
    height: 40px;
    margin-top: 32px;
  }
`;

const Content = styled.div`
  display: grid;
  gap: 16px;
  grid-auto-flow: row;
`;

const TransferIconWrapper = styled(TransferIcon)`
  color: ${({ theme }) => theme.system.gray[9]};
  cursor: pointer;
  display: inline-block;
  :hover {
    color: ${({ theme }) => theme.system.gray[9]};
  }

  margin: 34px 0 0;
`;

const FromTo = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 8px;
`;

const Block = styled.div`
  & > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.system.gray[9]};
    font-size: 12px;
    font-weight: 600;
  }
`;

const MAX = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.system.primary[7]};
`;

const Error = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.system.red[5]};
`;

const InputWrapper = styled(InputNumber)`
  background: transparent;
`;

const Token = styled.span`
  margin-left: 8px;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
`;

type TProps = {
  onCancel: (e: boolean) => void;
};

const TransferContainer = memo<TProps>(({ onCancel }) => {
  const { t } = useLocalization();

  const [loading, setLoading] = useState(false);
  const { accountId } = useAccount();
  // const [coins, setCoins] = useState<string[]>();
  const [accounts, setAccounts] = useState<ITransferAccount[]>();
  const [balances, setBalacnes] = useState<ITransferBalance[]>();
  const [icons, setIcons] = useState<{ [key in string]: string }>();
  const [withdrawalDecimals, setWithdrawalDecimals] = useState<{ [key in string]: string }>();
  const [params, setParams] = useState<ITransferParams>({
    fromAccountId: accountId || "",
    toAccountId: "",
    transferAmount: "",
    transferInstrument: ""
  });

  useRequest(getCoinIcons, {
    cacheKey: `UIKIT_getCoinIcons`,
    cacheTime: -1,
    staleTime: 3_000,
    onSuccess: (res) => {
      res.success && setIcons(res.data);
    }
  });

  useRequest(getDecimals, {
    cacheKey: `UIKIT_getDecimals`,
    cacheTime: -1,
    staleTime: 3_000,
    onSuccess: (res) => {
      res.success && setWithdrawalDecimals(res.data);
    }
  });

  useRequest(getTransferAccounts, {
    cacheKey: `UIKIT_getTransferAccounts`,
    cacheTime: -1,
    staleTime: 3_000,
    refreshDeps: [accountId],
    onSuccess: (res) => {
      res.success && setAccounts(res.data);
    }
  });

  useRequest(getTransferBalance.bind(null, params.fromAccountId), {
    cacheKey: `UIKIT_getTransferBalance`,
    cacheTime: -1,
    staleTime: 3_000,
    refreshDeps: [accountId, params.fromAccountId],
    onSuccess: (res) => {
      res.success && setBalacnes(res.data);
    }
  });

  // useRequest(getTransferCoins, {
  //   cacheKey: `UIKIT_getTransferCoins`,
  //   cacheTime: -1,
  //   staleTime: 3_000,
  //   refreshDeps: [accountId],
  //   onSuccess: (res) => {
  //     res.success && setCoins(res.data);
  //   }
  // });

  // const getBalance = useCallback(async () => {
  //   const res = await getTransferBalance(params.fromAccountId);
  //   if (res.success) {
  //     setBalacnes(res.data);
  //   }
  // }, [params.fromAccountId]);

  // useEffect(() => {
  //   if (params.fromAccountId) {
  //     getBalance();
  //   } else {
  //     setBalacnes(undefined);
  //   }
  // }, [params.fromAccountId, getBalance]);

  const balance = useMemo(
    () => balances?.find((p) => p.instrumentId === params.transferInstrument)?.available,
    [balances, params.transferInstrument]
  );

  const verify = useMemo(() => {
    if (
      !params.fromAccountId ||
      !params.toAccountId ||
      !params.transferAmount ||
      !params.transferInstrument ||
      Number(params.transferAmount) <= 0
    ) {
      return { disable: true };
    }
    if (balance) {
      if (params.transferAmount) {
        if (+params.transferAmount > +balance) {
          return {
            disable: true,
            message: t("Amount exceeds available balance")
          };
        } else {
          return { disable: false };
        }
      } else {
        return { disable: true };
      }
    } else {
      return { disable: true };
    }
  }, [params, balance]);

  const onFinish = useCallback(async () => {
    if (!verify.disable) {
      setLoading(true);
      const res = await submitTransfer(params);
      if (res.success) {
        toast.success(t("succeeded"), {
          containerId: POPOVER.MESSAGE
        });
        // onCancel(false);
      } else {
        toast.error(res.message, {
          containerId: POPOVER.MESSAGE
        });
      }
      setLoading(false);
    }
  }, [verify.disable, params]);

  return (
    <Wrapper>
      <Content>
        <FromTo>
          <Block>
            <div>{t("From")}</div>
            <Select
              value={params.fromAccountId}
              onChange={(e) => e && setParams((v) => ({ ...v, fromAccountId: e.toString() }))}
            >
              {accounts?.map((p) => (
                <Select.Option key={p.accountId} value={p.accountId} disabled={params.toAccountId === p.accountId}>
                  {p.accountName}
                </Select.Option>
              ))}
            </Select>
          </Block>
          <TransferIconWrapper
            onClick={setParams.bind(null, {
              ...params,
              fromAccountId: params.toAccountId,
              toAccountId: params.fromAccountId
            })}
          />
          <Block>
            <div>{t("To")}</div>
            <Select
              value={params.toAccountId}
              onChange={(e) => e && setParams((v) => ({ ...v, toAccountId: e.toString() }))}
            >
              {accounts?.map((p) => (
                <Select.Option key={p.accountId} value={p.accountId} disabled={params.fromAccountId === p.accountId}>
                  {p.accountName}
                </Select.Option>
              ))}
            </Select>
          </Block>
        </FromTo>
        <Block>
          <div>{t("Coin")}</div>
          <Select
            value={params.transferInstrument}
            onChange={(e) => e && setParams((v) => ({ ...v, transferInstrument: e.toString() }))}
            search
          >
            {balances?.map((p) => {
              return (
                <Select.Option key={p?.instrumentId} value={p?.instrumentId}>
                  <OptionItem>
                    <Image
                      src={icons?.[p?.instrumentId] || `${DOMAIN?.UI + "/images/defaultIcon.png"}`}
                      size={16}
                      circle
                      alt=""
                    />
                    <Token>{p.instrumentId === "xOX" ? "OX Credits" : p.instrumentId}</Token>
                  </OptionItem>
                </Select.Option>
              );
            })}
          </Select>
        </Block>
        <Block>
          <div>
            <span>{t("Amount")}</span>
            <span>
              {t("Max available:")}
              &nbsp;
              {balance ?? "--"}&nbsp;
              {params.transferInstrument === "xOX" ? t("OX Credits") : params.transferInstrument}
            </span>
          </div>
          <InputWrapper
            value={params.transferAmount}
            onChange={(transferAmount) => setParams((v) => ({ ...v, transferAmount }))}
            step={formatToDecimalsSize(withdrawalDecimals?.[params.transferInstrument])}
            min={formatToDecimalsSize(withdrawalDecimals?.[params.transferInstrument])}
            afterNode={
              <MAX
                onClick={setParams.bind(null, {
                  ...params,
                  transferAmount: balance ?? ""
                })}
              >
                {t("Max")}
              </MAX>
            }
            error={verify.message}
          />
        </Block>
      </Content>
      <Button colorScheme="primary" disabled={verify.disable} onClick={onFinish} loading={loading}>
        {t("Confirm")}
      </Button>
    </Wrapper>
  );
});

const Transfer: React.FC = () => {
  const { isTransferModal, setTransferModal } = useUIKit();
  const { t } = useLocalization();

  return (
    <Modal isOpen={isTransferModal} onCancel={setTransferModal} renderTitle={t("Transfer")} width={520} destroyOnClose>
      <TransferContainer onCancel={setTransferModal} />
    </Modal>
  );
};

export default Transfer;
