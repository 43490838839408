import BigNumber from "bignumber.js";

/**
 *
 * @param precision 传入需要算出decmail的数据
 * @example  0.001 => 3, 1 => 0, -0.0001 => 4
 * @returns decmail
 */

export const formatToDecimals = (precision?: string | number) => {
  if (precision?.toString().includes(".")) {
    return precision.toString().split(".")?.[1]?.length;
  } else {
    return 0;
  }
};

/**
 *
 * @param length 小数位
 * @example  3 => 0.001, 0 => 0
 * @returns
 */
export const formatToDecimalsSize = (length?: string | number) => {
  if (length) {
    let a = "1";
    for (let i = 0; i < +length - 1; i++) {
      a = "0" + a;
    }
    return "0." + a;
  } else {
    return "0";
  }
};

/**
 *
 * @param payload 需要格式化的数据 错误数据默认返回“0”，比如 NaN Infinity undefined null
 * @param decimals 小数点位数, 传入 undefined 小数位则不做处理返回, 0则删除所有小数位, 大于当前小数位数则补0
 * @param positiveSign 是否加上正号
 * @returns 千位格式化，小数点按照截取的方式,不进行舍入, method: Bignumber.toFormat([dp [, rm]]) ⇒ string, ROUNDING_MODE=BigNumber.ROUND_DOWN
 */

export const formatToThousands = (
  payload?: string | number | null | BigNumber,
  decimals?: number,
  positiveSign?: boolean
) => {
  if (!payload && typeof payload !== "number") return "0";

  const formatBN = payload instanceof BigNumber ? payload : new BigNumber(payload);

  if (!formatBN.isFinite()) return "0";

  const symbol = positiveSign && formatBN.isGreaterThan(0) ? "+" : "";

  if (typeof decimals === "undefined") {
    return symbol + formatBN.toFormat(decimals);
  } else {
    return symbol + formatBN.toFormat(decimals, BigNumber.ROUND_DOWN);
  }
};

/**
 *
 * @param payload 需要格式化的数据 错误数据默认返回“0”，比如 NaN Infinity undefined null
 * @param decimals 小数点位数, 传入 undefined 小数位则不做处理返回, 0则删除所有小数位, 大于当前小数位数则补0
 * @param positiveSign 是否加上正号
 * @returns 返回一个字符串, 小数点按照截取的方式,不进行舍入, method: Bignumber.toFixed([dp [, rm]]) ⇒ string, ROUNDING_MODE=BigNumber.ROUND_DOWN
 */

export const formatToFixed = (
  payload?: string | number | null | BigNumber,
  decimals?: number,
  positiveSign?: boolean
) => {
  if (!payload && typeof payload !== "number") return "0";

  const formatBN = payload instanceof BigNumber ? payload : new BigNumber(payload);

  if (!formatBN.isFinite()) return "0";

  const symbol = positiveSign && formatBN.isGreaterThan(0) ? "+" : "";

  if (typeof decimals === "undefined") {
    return symbol + formatBN.toFixed();
  } else {
    return symbol + formatBN.toFixed(decimals, BigNumber.ROUND_DOWN);
  }
};

/**
 * @name formatToPercentageByThousands 百分比格式化千位符
 * @param payload 需要百分比格式化的数据
 * @param decimals 小数点位数, 传入 undefined 小数位则不做处理返回, 0则删除所有小数位, 大于当前小数位数则补0
 * @param positiveSign 是否加上正号
 * @returns 返回一个百分比千位字符串，小数点按照截取的方式，不进行舍入,
 */

export const formatToPercentageByThousands = (
  payload?: string | number | null | BigNumber,
  decimals?: number,
  positiveSign?: boolean
) => {
  if (!payload) return formatToThousands(0, decimals) + "%";
  const formatBN = payload instanceof BigNumber ? payload : new BigNumber(payload);
  const symbol = positiveSign && formatBN.isGreaterThan(0) ? "+" : "";
  return symbol + formatToThousands(formatBN.multipliedBy(100), decimals) + "%";
};

/**
 * @name formatToPercentageByFixed 百分比格式化
 * @param payload 需要百分比格式化的数据
 * @param decimals 小数点位数, 传入 undefined 小数位则不做处理返回, 0则删除所有小数位, 大于当前小数位数则补0
 * @param positiveSign 是否加上正号
 * @returns 返回一个百分比千位字符串，小数点按照截取的方式，不进行舍入,
 */

export const formatToPercentageByFixed = (
  payload?: string | number | null | BigNumber,
  decimals?: number,
  positiveSign?: boolean
) => {
  if (!payload) return formatToFixed(0, decimals) + "%";
  const formatBN = payload instanceof BigNumber ? payload : new BigNumber(payload);
  const symbol = positiveSign && formatBN.isGreaterThan(0) ? "+" : "";
  return symbol + formatToFixed(formatBN.multipliedBy(100), decimals) + "%";
};
