import React, { createContext, useEffect, useRef, useState } from "react";
import { getRecaptchaId, getRecaptchaKey } from "../services/requests";
import { IRecaptchaContent } from "./type";
import pubsub from "pubsub-js";
import { RECAPTCHA_SCRIPT_LOAD } from "../types/enum";
import { POPOVER, toast, TOAST_ID } from "../components/Toast";
import { useAsyncEffect } from "ahooks";
import { initGeetest } from "../utils/gt";
import { useUIKit } from "../hooks";

type TProps = {
  children?: React.ReactNode;
};
type IGeetestResponce = {
  pass_token: string;
  lot_number: string;
  captcha_output: string;
  isOffline: boolean;
  gen_time: string;
  captcha_id: string;
};
export const Context = createContext<IRecaptchaContent>({
  key: "",
  onRecaptchaVerify: () => {}
});

const RecaptchaProvider: React.FC<TProps> = ({ children }) => {
  const [key, setKey] = useState<string>("");
  const [isDone, setIsDone] = useState<boolean>(false);
  const status = useRef<RECAPTCHA_SCRIPT_LOAD.COMPLETED | RECAPTCHA_SCRIPT_LOAD.FAILED>();
  const captchaObjRef = useRef<any>(null);
  const { locale } = useUIKit();
  useAsyncEffect(async () => {
    const captchaId = await getRecaptchaId();
    if (!captchaId.success) {
      return;
    }
    setKey(captchaId.data);
    await window.initGeetest4(
      {
        captchaId: captchaId.data,
        product: "bind",
        language: locale,
        timeout: 30000
      },
      (captchaObj: any) => {
        captchaObjRef.current = captchaObj;
        captchaObj
          .onNextReady(function () {
            status.current = RECAPTCHA_SCRIPT_LOAD.COMPLETED;
          })
          .onError(function () {
            status.current = RECAPTCHA_SCRIPT_LOAD.FAILED;
          });
      }
    );
  }, [isDone]);
  const onRecaptchaVerify = (onSuccess?: (token: string) => void, onError?: (err?: unknown) => void) => {
    if (status.current === RECAPTCHA_SCRIPT_LOAD.COMPLETED) {
      if (captchaObjRef.current) {
        captchaObjRef.current.showCaptcha();

        const successHandler = () => {
          const result: IGeetestResponce = captchaObjRef.current.getValidate();
          const base64String = window.btoa(JSON.stringify(result));
          onSuccess?.(base64String);
          setIsDone(!isDone);
          captchaObjRef.current.destroy();
        };

        const errorHandler = () => {
          setIsDone(!isDone);
          onError?.("Please refresh and try again (101)");
        };
        const closedHandler = () => {
          setIsDone(!isDone);
          onError?.("Captcha closed");
          toast.error("Please refresh and try again (102)", {
            containerId: POPOVER.MESSAGE,
            updateId: TOAST_ID.RECAPTCHA_ERROR,
            toastId: TOAST_ID.RECAPTCHA_ERROR
          });
        };
        captchaObjRef.current.onSuccess(successHandler).onError(errorHandler).onClose(closedHandler);
      } else {
        setIsDone(!isDone);
        toast.error("Please refresh and try again (103)", {
          containerId: POPOVER.MESSAGE,
          updateId: TOAST_ID.RECAPTCHA_ERROR,
          toastId: TOAST_ID.RECAPTCHA_ERROR
        });
      }
    } else {
      toast.error("Please refresh and try again (104)", {
        containerId: POPOVER.MESSAGE,
        updateId: TOAST_ID.RECAPTCHA_ERROR,
        toastId: TOAST_ID.RECAPTCHA_ERROR
      });
      setIsDone(!isDone);
      onError?.("Please refresh and try again");
    }
  };

  return (
    <Context.Provider
      value={{
        key,
        onRecaptchaVerify: onRecaptchaVerify
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default RecaptchaProvider;
