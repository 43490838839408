import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const DashboardIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="M2.5 17.5a.833.833 0 0 1-.833-.833V3.333A.833.833 0 0 1 2.5 2.5h15a.833.833 0 0 1 .834.833v13.334a.833.833 0 0 1-.834.833h-15Zm3.334-9.167h-2.5v7.5h2.5v-7.5Zm10.833 0H7.5v7.5h9.167v-7.5Zm0-4.166H3.334v2.5h13.333v-2.5Z"
      />
    </svg>
  );
};

export default DashboardIcon;
