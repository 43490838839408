import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import { ILoadingProps } from "./type";
import { keyframes, useTheme } from "@emotion/react";
import { cx } from "../../utils/share";
import { Player } from "@lottiefiles/react-lottie-player";
import loadingJson from "./loading.json";
import mogLoadingJson from "./mog-loading.json";
import debounce from "lodash/debounce";

const CSS_LOADER_LEFT = keyframes`
 0% {
    transform:rotateY(0) translateY(-20px);
  }
  50%{
    transform:rotateY(180deg) translateY(-20px);

  }
  100% {
    transform:rotateY(360deg) translateY(-20px);
  }
`;

const Wrapper = styled.div<{ opt?: number }>`
  position: relative;

  .${({ theme }) => theme.prefixCls}-loading-blur {
    user-select: none;
    pointer-events: none;
    opacity: ${({ opt }) => opt};
  }

  .${({ theme }) => theme.prefixCls}-loading-container {
    position: relative;
    transition: opacity 0.3s;
  }
`;

const PlayerWrapper = styled(Player)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  z-index: ${({ theme }) => theme.zIndices.loading};
`;

const OXFUNLoader = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndices.loading};
  & > svg:first-of-type {
    animation-name: ${CSS_LOADER_LEFT};
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  & > svg:last-of-type {
    transform: translateY(-16px);
  }
`;

function shouldDelay(loading?: boolean, delay?: number): boolean {
  return !!loading && !!delay && !isNaN(Number(delay));
}

const Loading = forwardRef<HTMLDivElement, ILoadingProps>(
  ({ children, loading, className, zIndex, opacity, type, delay = 0, ...props }, ref) => {
    const { prefixCls, themeMode } = useTheme();

    const [wrapperLoading, setWrapperLoading] = React.useState<boolean>(!!loading && !shouldDelay(loading, delay));

    React.useEffect(() => {
      if (loading) {
        const showLoading = debounce(() => {
          setWrapperLoading(true);
        }, delay);
        showLoading();
        return () => {
          showLoading.cancel();
        };
      }

      setWrapperLoading(false);
    }, [delay, loading]);

    return (
      <Wrapper
        className={cx(`${prefixCls}-loading`, className)}
        data-loading={wrapperLoading ? "" : undefined}
        opt={opacity}
        {...props}
        ref={ref}
      >
        {wrapperLoading && type === "NORMAL" && (
          <PlayerWrapper
            autoplay
            loop
            src={themeMode === "mog" ? mogLoadingJson : loadingJson}
            className={`${prefixCls}-loading-icon`}
            style={{ zIndex }}
          />
        )}
        {wrapperLoading && type === "LOGO" && (
          <OXFUNLoader className={`${prefixCls}-loading-icon`} style={{ zIndex }}>
            <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
              <path
                fill="#C1FF72"
                d="M61.36 20.58 47.41 34.53h-20l-4.05 4.05 8.67 8.67 8.67-8.67-2.05-2.05h5.66l2.05 2.05-14.33 14.33L17.72 38.6l8.06-8.07h19.97l9.95-9.95-5.75-5.75L52.78 12l8.58 8.58ZM18.31 30.53h4.64l-4 4h-2.3L2.7 20.58l8.55-8.54 2.83 2.83-5.72 5.71 9.95 9.95Z"
              />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width={72} height={15} fill="none">
              <path
                fill="#C1FF72"
                fillRule="evenodd"
                d="M0 14.01h9.799l1.837-1.838V0H0v14.01ZM2.626 2.457h6.392v9.11H2.626v-9.11Zm17.57 2.251 3.176-4.7h3.085l-4.715 6.981 4.715 7.02h-3.154l-3.146-4.7-3.177 4.7h-3.047l4.685-6.981-4.677-7.02h3.077l.03.046 3.147 4.654Zm7.792 6.477h2.833v2.832h-2.833v-2.832Zm5.13 2.825h2.656V8.168h6.852v-2.45h-6.852v-3.26h8.153V.007h-10.81V14.01Zm36.195-4.862V.008H72V14.01h-2.312l-.03-.046-6.707-9.01v9.056h-2.687V.008h2.174l.03.038 6.845 9.102ZM48.88 11.56h6.431V.008h2.657v12.164L56.13 14.01h-9.906V.008h2.656V11.56Z"
                clipRule="evenodd"
              />
            </svg>
          </OXFUNLoader>
        )}
        <div className={cx(`${prefixCls}-loading-container`, wrapperLoading && `${prefixCls}-loading-blur`)}>
          {children}
        </div>
      </Wrapper>
    );
  }
);

Loading.defaultProps = {
  opacity: 1,
  type: "NORMAL"
};

export default Loading;
