import * as React from "react";
import { SVGProps } from "react";
const HelpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="M512 170.624a341.312 341.312 0 1 0 0 682.688 341.312 341.312 0 0 0 0-682.688zM85.312 512a426.688 426.688 0 1 1 853.312 0 426.688 426.688 0 0 1-853.312 0zm400-253.888a170.624 170.624 0 0 1 69.312 333.76v5.44a42.688 42.688 0 0 1-85.312 0v-42.688C469.312 531.072 488.384 512 512 512a85.312 85.312 0 1 0-81.28-111.68 42.688 42.688 0 0 1-81.152-26.368 170.624 170.624 0 0 1 135.68-115.84zm-18.112 467.2c0-23.552 19.072-42.688 42.624-42.688h4.288c23.552 0 42.688 19.136 42.688 42.688v4.288a42.688 42.688 0 0 1-42.624 42.624h-4.224A42.688 42.688 0 0 1 467.2 729.6v-4.288z" />
  </svg>
);
export default HelpIcon;
