import * as React from "react";
import { SVGProps } from "react";

const TelegramIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" {...props}>
      <g clipPath="url(#telegram-icon)">
        <path
          fillRule="evenodd"
          d="M1.65 11.562c6.442-2.807 10.738-4.657 12.888-5.551C20.675 3.458 21.95 3.015 22.782 3c.182-.004.591.041.856.256.223.182.285.427.314.599.03.172.066.563.037.87-.332 3.494-1.771 11.974-2.503 15.888-.31 1.656-.92 2.211-1.51 2.266-1.284.118-2.259-.848-3.502-1.663-1.945-1.275-3.043-2.069-4.931-3.313-2.182-1.438-.768-2.228.476-3.52.325-.338 5.98-5.481 6.089-5.948.014-.058.026-.275-.103-.39-.13-.115-.32-.076-.457-.045-.196.045-3.303 2.099-9.322 6.162-.882.605-1.681.9-2.397.885-.789-.017-2.307-.446-3.435-.813-1.384-.45-2.484-.688-2.388-1.452.05-.398.598-.805 1.644-1.22Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="telegram-icon">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TelegramIcon;
