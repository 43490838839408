import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { RecaptchaV2Props } from "./type";
import { useRequest } from "ahooks";
import { getRecaptchaKey } from "../../services/requests";
import { POPOVER, toast } from "../Toast";
import styled from "@emotion/styled";
import { Modal } from "../Modal";
import { Loading } from "../Loading";
const Wrapper = styled(Modal)`
  & > .oxfun-modal-container {
    & > .oxfun-modal-content {
      background: transparent;
    }
  }
`;
const RecaptchaV2 = forwardRef<{ executeRecaptcha: () => void }, RecaptchaV2Props>(
  ({ onVerify, onReady, isOpen, setOpen, size = "normal", theme = "dark", tabIndex }, ref) => {
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [ready, setReady] = React.useState<boolean>(false);
    const handleVerify = (value: string | null) => {
      if (typeof value === "string") {
        onVerify(value);
      }
    };
    const { data: sitekey } = useRequest(getRecaptchaKey);
    return (
      <Wrapper isOpen={isOpen} zIndex={99999} onCancel={setOpen} isCancelBtn={false} destroyOnClose>
        <Loading loading={!ready} type="LOGO">
          {sitekey?.data ? (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={sitekey?.data}
              size={size}
              hl="en"
              theme={theme}
              isolated
              asyncScriptOnLoad={() => {
                setReady(true);
                onReady?.(true);
              }}
              onError={(e: any) => {
                toast.error(e, {
                  containerId: POPOVER.MESSAGE,
                  toastId: "recaptcha-error"
                });
                console.log(e, "recaptcha-error");
              }}
              tabIndex={tabIndex}
              onChange={handleVerify}
            />
          ) : null}
        </Loading>
      </Wrapper>
    );
  }
);

export default RecaptchaV2;
