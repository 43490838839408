import { Button, useLocalization, useUIKit } from "@oxfun/uikit";
import { memo } from "react";
import { IConnectWalletProps } from "./type";

const ConnectWalletButton = memo<IConnectWalletProps>((props) => {
  const { t } = useLocalization();

  const { setConnectWalletModal } = useUIKit();

  return (
    <Button
      colorScheme="primary"
      size="extra-small"
      onClick={() => {
        setConnectWalletModal?.(true);
      }}
      {...props}
    >
      {t("Connect wallet")}
    </Button>
  );
});

export default ConnectWalletButton;
