import {
  IAccounts,
  IBanner,
  ICoinBalance,
  IGeeTest,
  ILendPool,
  ILendPrams,
  IQRCodeStatus,
  IRecaptchaHeaders,
  IRedeemPool,
  ITransferAccount,
  ITransferBalance,
  ITransferParams,
  IUser,
  IUserInfo
} from "../types";
import { get, post } from "./axios";
import { REQUEST_URLS, __DEV__ } from "../config";

const channelHeader = __DEV__ ? { channel: "MOBILE" } : { channel: "COMPUTER" };

export const geetestValidate = (data: any) => {
  return post(REQUEST_URLS.GEETEST_VALIDATE, data);
};

export const geetestInit = (email: string) => {
  return post<IGeeTest>(REQUEST_URLS.GEETEST_INIT, {
    email,
    clientType: "web"
  });
};

export const logout = () => {
  return get<boolean>(REQUEST_URLS.LOGOUT);
};

export const getAccounts = () => {
  return get<{ accounts: IAccounts[] }>(REQUEST_URLS.ACCOUNTS);
};

export const checkToken = () => {
  return get(
    REQUEST_URLS.TOKEN_CHECK,
    {},
    {
      headers: {
        symbol: 0
      }
    }
  );
};

export const switchAccountLogin = (accountId: string) => {
  return get<IUser>(
    REQUEST_URLS.SWITCH_ACCOUT,
    {},
    {
      headers: {
        accountId
      }
    }
  );
};

export const getRecaptchaKey = () => {
  return get<string>(REQUEST_URLS.GET_RECAPTCHA_KEY);
};

export const emailLogin = (data: { email: string; password: string }, headers: IRecaptchaHeaders) => {
  return post<IUser>(REQUEST_URLS.LOGIN, data, {
    headers: {
      ...channelHeader,
      "g-recaptcha-response": headers["g-recaptcha-response"]
    }
  });
};

export const getBanner = (params: { url: string }) => {
  return post<IBanner[] | null>(REQUEST_URLS.GET_BANNER, params, {
    headers: {
      symbol: 0
    }
  });
};

export const getCoinBalance = (coin: string) => {
  return get<ICoinBalance>(REQUEST_URLS.GET_COIN_BALACE + coin);
};

export const submitLend = (params: ILendPrams) => {
  return post(REQUEST_URLS.LEND, params, {
    headers: {
      symbol: ""
    }
  });
};
export const submitRedeem = (params: ILendPrams) => {
  return post(REQUEST_URLS.REDEEM, params, {
    headers: {
      symbol: ""
    }
  });
};

export const LendPool = () => {
  return get<ILendPool[]>(
    REQUEST_URLS.LEND_POOL,
    {},
    {
      headers: {
        symbol: ""
      }
    }
  );
};

export const getRedeemBalance = (poolId: number) => {
  return get<IRedeemPool>(
    REQUEST_URLS.REDEEM_BALANCE + poolId,
    {},
    {
      headers: {
        symbol: ""
      }
    }
  );
};

export const uploadFile = async (file: FormData, onUploadProgress: (progressEvent: any) => void) => {
  return post<string>(REQUEST_URLS.UPLOAD_FILE, file, {
    headers: {
      symbol: 0
    },
    onUploadProgress
  });
};

export const readFile = (url: string) => {
  return get<Blob>(REQUEST_URLS.READ_FILE + url, () => {}, {
    responseType: "blob",
    headers: {
      symbol: ""
    }
  });
};

export const validateAddress = (address: string) => {
  return get<boolean>(REQUEST_URLS.VALIDATE_ADDRESS + address);
};

export const getAddressNonce = (address: string) => {
  return get<string>(REQUEST_URLS.GET_ADDRESS_NONCE, {
    publicAddress: address
  });
};
export const getSolanaNonce = (address: string) => {
  return get<string>(REQUEST_URLS.GET_SOLANA_NONCE, {
    publicAddress: address
  });
};

export const getWalletAuth = (
  data: {
    signature: string;
    publicAddress: string;
    referCode?: string;
  },
  headers: {
    token: string;
    deviceType: string;
  }
) => {
  return post<IUser>(REQUEST_URLS.WALLET_AUTH, data, {
    headers: {
      "g-recaptcha-response": headers.token,
      "x-device-type": headers.deviceType,
      symbol: "",
      ...channelHeader
    }
  });
};
export const getSolanaAuth = (
  data: {
    signature: string;
    publicAddress: string;
    referCode?: string;
  },
  headers: {
    token: string;
    deviceType: string;
  }
) => {
  return post<IUser>(REQUEST_URLS.SOLANA_AUTH, data, {
    headers: {
      "g-recaptcha-response": headers.token,
      "x-device-type": headers.deviceType,
      symbol: "",
      ...channelHeader
    }
  });
};

export const getUserInfo = () => {
  return get<IUserInfo>(REQUEST_URLS.USER_INFO);
};

export const getQRCode = () => {
  return get<string>(REQUEST_URLS.GET_QRCODE);
};

export const getQRCodeStatus = (code: string) => {
  return get<IQRCodeStatus>(REQUEST_URLS.GET_QRCODE_STATUS, { qr: code });
};

export const confirmQRCodeLogin = (qr: string, status: IQRCodeStatus) => {
  return post<unknown>(REQUEST_URLS.CONFIRM_QRCODE_LOGIN, { qr, status });
};

export const getTransferCoins = () => {
  return get<string[]>(REQUEST_URLS.GET_TRANSFER_COINS);
};

export const getTransferAccounts = () => {
  return get<ITransferAccount[]>(REQUEST_URLS.GET_TRANSFER_ACCOUNTS);
};

export const getTransferBalance = (accountId: string) => {
  return get<ITransferBalance[]>(REQUEST_URLS.GET_TRANSFER_BALANCE, {
    accountId
  });
};

export const submitTransfer = (params: ITransferParams) => {
  return post<unknown>(REQUEST_URLS.SUBMIT_TRANSFER, params);
};

export const getDecimals = () => {
  return get<{ [key: string]: string }>(REQUEST_URLS.GET_DECIMALS);
};

export const getCoinIcons = () => {
  return get<{ [key: string]: string }>(REQUEST_URLS.GET_COIN_ICONS);
};

export const checkRewards = () => {
  return post<boolean>(
    REQUEST_URLS.CHECK_REWARDS,
    {},
    {
      headers: {
        symbol: ""
      }
    }
  );
};

export const getRewards = () => {
  return post<boolean>(
    REQUEST_URLS.GET_REWARDS,
    {},
    {
      headers: {
        symbol: ""
      }
    }
  );
};

export const getRecaptchaId = () => {
  return get<string>(
    REQUEST_URLS.CAPTCHA_ID,
    {},
    {
      headers: {
        symbol: ""
      }
    }
  );
};
