import styled from "@emotion/styled";
import React, { useState } from "react";
import { memo } from "react";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import SubNavItem from "./SubNavItem";
import { ClassNames } from "@emotion/react";
import { ExternalLink } from "../../../../components/ExternalLink";
import { DOMAIN } from "../../../../config";
import { ISystem } from "../../type";
import { ChevronDownIcon } from "../../../../icons";
import useLocalization from "../../../../hooks/useLocalization";
import PrisonIcon from "../svgs/PrisonIcon";
import YieldIcon from "../svgs/YieldIcon";
import LeaderboardIcon from "../svgs/LeaderboardIcon";
import CopyTradingIcon from "../svgs/CopyTradingIcon";
import PerpsIcon from "../svgs/PerpsIcon";
import { Button } from "../../../../components/Button";
import fire from "./fire.json";
import { Player } from "@lottiefiles/react-lottie-player";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 16px;
  &[data-drawer] {
    flex-direction: column;
    transform: translate(0);
    height: fit-content;
    margin-left: 0;
  }

  & > .oxfun-external-link {
    height: 100%;
  }
`;

const DropdownWrapper = styled(Dropdown)`
  padding: 0 16px;
  white-space: nowrap;

  @media screen and (max-width: 1440px) {
    padding: 0 12px;
  }

  @media screen and (max-width: 1280px) {
    padding: 0 10px;
  }
`;

const DrawerBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.system.gray[9]};
  overflow: hidden;
`;

const DrawerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  padding: 0 16px;
  color: inherit;
  :hover,
  &[data-expand] {
    background: ${({ theme }) => theme.system.gray[1]};
    & > svg {
      color: ${({ theme }) => theme.system.primary[6]};
    }
  }
  & > div {
    display: grid;
    gap: 16px;
    grid-auto-flow: column;
    align-items: center;
  }
  & > svg {
    transition: transform 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: ${({ theme }) => theme.system.gray[9]};
    font-size: 24px;
  }

  &[data-expand] > svg {
    transform: rotateX(180deg);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

const DrawerOverlayWrapper = styled.div`
  width: 100%;
  transition: max-height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  max-height: 0;
  &[data-expand] {
    max-height: 1000px;
  }
`;

type TProps = { isDrawer?: boolean };

const Nav = memo<TProps>(({ isDrawer }) => {
  const { t } = useLocalization();

  const NAV: ISystem["Nav"][] = [
    {
      symbol: "Trade",
      text: t("Trade"),
      path: DOMAIN?.UI + "/markets",
      isHot: true
      // subNav: [
      //   {
      //     symbol: "Perpetuals",
      //     text: t("Perpetuals"),
      //     path: DOMAIN?.UI + "/markets",
      //     icon: <PerpsIcon />
      //   },
      //   // {
      //   //   symbol: "Leaderboards",
      //   //   text: t("Leaderboards"),
      //   //   path: DOMAIN?.UI + "/leaderboards",
      //   //   icon: <LeaderboardIcon />
      //   // },
      //   // {
      //   //   symbol: "Copy Trading",
      //   //   text: t("Copy Trading"),
      //   //   path: DOMAIN?.UI + "/copy-trading",
      //   //   icon: <CopyTradingIcon />
      //   // }
      // ]
    },
    {
      symbol: "Strategies",
      text: t("Strategies"),
      path: DOMAIN?.UI + "/vaults"
    },
    // {
    //   symbol: "Yield",
    //   text: t("Yield"),
    //   path: DOMAIN?.UI + "/yield"

    //   // subNav: [
    //   //   {
    //   //     symbol: "Earn Yield",
    //   //     text: t("Earn Yield"),
    //   //     path: DOMAIN?.UI + "/yield",
    //   //     icon: <YieldIcon />
    //   //   }
    //   //   // {
    //   //   //   symbol: "Prison",
    //   //   //   text: t("Prison"),
    //   //   //   path: DOMAIN?.UI + "/prison",
    //   //   //   icon: <PrisonIcon />
    //   //   // }
    //   // ]
    // },
    {
      symbol: "Referral",
      text: t("Referral"),
      path: DOMAIN?.UI + "/referral"
    },
    {
      symbol: "Analytics",
      text: t("Analytics"),
      path: "https://analytics.ox.fun"
    },
    {
      symbol: "Trade $3AC",
      text: t("Trade $3AC"),
      path: DOMAIN?.UI + "/convert-ox"
    },
    {
      symbol: "Egirl",
      text: t("Egirl"),
      path: DOMAIN?.UI + "/egirl"
    }
    // {
    //   symbol: "OX Chain",
    //   text: t("OX Chain"),
    //   path: DOMAIN?.UI + "/chain"
    // }
    // {
    //   symbol: "Learn",
    //   text: t("Learn"),
    //   path: DOMAIN?.UI + "/learn"
    // }
  ];

  const [isUnfold, setUnfold] = useState<Record<string, boolean | undefined>>({});

  const [isDropdownOpen, setDropdownOpen] = useState<Record<string, boolean | undefined>>({});

  return (
    <Wrapper data-drawer={isDrawer ? "" : undefined}>
      {!isDrawer ? (
        <ClassNames>
          {({ css, theme }) =>
            NAV.map((p) => {
              // if (p.symbol === "Trade") {
              //   return (
              //     <ExternalLink href={p.path} key={p.symbol} target={p.target}>
              //       <Button colorScheme="primary" style={{ height: 32 }}>
              //         {p.text}
              //       </Button>
              //     </ExternalLink>
              //   );
              // }
              return (
                <ExternalLink href={p.path} key={p.symbol} target={p.target}>
                  <DropdownWrapper
                    arrowIcon={p.subNav?.length ? undefined : null}
                    overlay={
                      p.subNav?.length
                        ? p.subNav.map((r) => (
                            <SubNavItem
                              key={r.symbol}
                              {...r}
                              onClick={() => {
                                setDropdownOpen((o) => ({ ...o, [p.symbol]: false }));
                              }}
                            />
                          ))
                        : undefined
                    }
                    // overlayClassName={css({
                    //   ".oxfun-dropdown-container": {
                    //     padding: 12,
                    //     // borderRadius: 24,
                    //     // borderColor: theme.system.blueGray[2],
                    //     // backdropFilter: "blur(12px)",
                    //     boxShadow: "none",
                    //     ".oxfun-text": {
                    //       maxWidth: 160
                    //     }
                    //   }
                    // })}
                    overlayClassName={css({
                      ".oxfun-dropdown-container": {
                        padding: 12,
                        boxShadow: "none",
                        "& > a": {
                          padding: "0 12px",
                          height: 56,
                          "& > svg": {
                            marginRight: 12
                          }
                        }
                      }
                    })}
                    isOpen={isDropdownOpen[p.symbol]}
                    onOpenChange={(e) => {
                      setDropdownOpen((r) => ({ ...r, [p.symbol]: e }));
                    }}
                  >
                    {p.text}
                    {p.isHot ? <Player autoplay loop src={fire} /> : null}
                  </DropdownWrapper>
                </ExternalLink>
              );
            })
          }
        </ClassNames>
      ) : (
        NAV.map((p) => (
          <DrawerBlock key={p.symbol}>
            <DrawerItem
              onClick={() => {
                setUnfold((e) => ({
                  ...e,
                  [p.symbol]: !e[p.symbol]
                }));
              }}
              data-expand={isUnfold[p.symbol] ? "" : undefined}
              as={p.subNav?.length ? "div" : "a"}
              {...(!p.subNav?.length && p.path
                ? {
                    href: p.path,
                    target: p.target
                  }
                : {})}
            >
              <div>
                {/* {p.icon || <MenuDefaultIcon />} */}
                <span>{p.text}</span>
              </div>

              {!!p.subNav?.length && <ChevronDownIcon />}
            </DrawerItem>
            {!!p.subNav?.length && (
              <DrawerOverlayWrapper data-expand={isUnfold[p.symbol] ? "" : undefined}>
                {p.subNav.map((r) => (
                  <SubNavItem key={r.symbol} {...r} isDrawer />
                ))}
              </DrawerOverlayWrapper>
            )}
          </DrawerBlock>
        ))
      )}
    </Wrapper>
  );
});

export default Nav;
