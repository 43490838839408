import { WSS_URL } from "../config";
import { getUserFromLS } from "../providers/hooks/useUserSync";
import { toast } from "react-toastify";
import pubsub from "pubsub-js";
import { NETWORK_STATUS } from "../types/enum";
import { POPOVER, TOAST_ID } from "../components/Toast/type";

/**
 * 0	CONNECTING	Socket has been created. The connection is not yet open.
 * 1	OPEN	The connection is open and ready to communicate.
 * 2	CLOSING	The connection is in the process of closing.
 * 3	CLOSED	The connection is closed or couldn't be opened.
 */

class WS {
  public ws: WebSocket | null = null;
  private subscribers: Set<Function> = new Set();
  private lastSubscribers: string[] = [];
  private pingTimeout = 2000;
  private pongTimeout = 2000;
  private connectionTimeout = 10000;
  private sendTimeout = 500;
  private pingMsg = "ping";
  public forbidReconnect = false;
  private pingTimeoutId = 0;
  private pongTimeoutId = 0;
  private sendTimeoutId = 0;
  private sendTimeoutLoginId = 0;
  private connectionTimeoutId = 0;

  private useThrottle = true; // Use throttle until authenticated
  private isAuthentication = false;
  public initialized = false;
  public token = "";

  constructor(isAuthentication?: boolean) {
    this.isAuthentication = !!isAuthentication;
    this.createWebSocket();
    this.subscribeNetwork();
  }

  private subscribeNetwork() {
    pubsub.subscribe(NETWORK_STATUS.EVENT, (event, status) => {
      if (status === NETWORK_STATUS.OFFLINE) {
        this.forbidReconnect = true;
        this.onclose();
      }
      if (status === NETWORK_STATUS.ONLINE) {
        this.forbidReconnect = false;
        this.reconnect();
      }
    });
  }

  public onOpenSlot() {}

  public authenticate() {
    const user = getUserFromLS();
    if (!user && this.isAuthentication) {
      clearTimeout(this.connectionTimeoutId);
      this.forbidReconnect = true;
      this.onclose();
    }
    if (user && this.isAuthentication) {
      const { token, loginId } = user;
      this.send({
        op: "login",
        data: { xCfToken: token, loginId },
        tag: new Date().getTime()
      });
      this.token = token;
    }
  }

  public createWebSocket() {
    try {
      this.ws = new WebSocket(WSS_URL);
      this.ws.binaryType = "arraybuffer";
      this.initEventHandle();
      this.authenticate();
      clearTimeout(this.connectionTimeoutId);
      this.connectionTimeoutId = window.setTimeout(() => {
        this.onclose();
      }, this.connectionTimeout);
    } catch (e) {
      this.reconnect();
      // toast.error("Failed to create websocket, reconnecting", {
      //   containerId: POPOVER.NOTIFICATION,
      //   toastId: TOAST_ID.CREATE_WEBSOCKET_FAILED,
      //   updateId: TOAST_ID.CREATE_WEBSOCKET_FAILED
      // });
      console.error("Failed to create websocket, reconnecting");
      throw e;
    }
  }

  public initEventHandle() {
    if (this.ws) {
      this.ws.onopen = () => {
        this.onOpenSlot();
        this.initialized = true;
        clearTimeout(this.connectionTimeoutId);
        this.heartCheck();
      };

      this.ws.onmessage = (e: any) => {
        this.onMessage(e);
        this.heartCheck();
      };

      this.ws.onclose = () => {
        this.reconnect();
      };
      this.ws.onerror = () => {
        this.onclose();
      };
    }
  }

  public subscribe(subscriber: any) {
    this.subscribers.add(subscriber);
  }

  public unsubscribe(subscriber: any) {
    this.subscribers.delete(subscriber);
  }

  private onMessage({ data }: any) {
    let parsedData: any = "";
    if (data !== "pong") {
      parsedData = JSON.parse(data);
      this.subscribers?.forEach((subscriber) => subscriber(JSON.parse(data)));
    }

    if (this.isAuthentication && this.useThrottle && parsedData && parsedData.event === "login" && parsedData.success) {
      this.useThrottle = false;
      this.lastSubscribers.length &&
        this.send({ op: "subscribe", args: this.lastSubscribers, tag: new Date().getTime() });
    }
  }

  public reconnect() {
    this.useThrottle = true;
    if (this.forbidReconnect) return;

    if (this.ws?.readyState === 2 || this.ws?.readyState === 3) {
      this.createWebSocket();
      this.lastSubscribers.length &&
        this.send({ op: "subscribe", args: this.lastSubscribers, tag: new Date().getTime() });
    }
  }

  public send(opArgPair: any) {
    if (this.ws?.readyState === 1) {
      this.sendMiddleware(opArgPair);
    } else if (this.ws?.readyState === 0) {
      if (opArgPair.op === "subscribe") {
        clearTimeout(this.sendTimeoutId);
        this.sendTimeoutId = window.setTimeout(() => {
          this.send(opArgPair);
        }, this.sendTimeout);
      } else if (opArgPair.op === "login") {
        clearTimeout(this.sendTimeoutLoginId);
        this.sendTimeoutLoginId = window.setTimeout(() => {
          this.send(opArgPair);
        }, this.sendTimeout);
      } else {
        // toast.error("WebSocket is connecting, please try again later", {
        //   containerId: POPOVER.NOTIFICATION,
        //   toastId: TOAST_ID.WEBSOCKET_CONNECTING,
        //   updateId: TOAST_ID.WEBSOCKET_CONNECTING
        // });
        console.error("WebSocket is connecting, please try again later");
      }
    } else {
      if (opArgPair.op !== "subscribe" && opArgPair.op !== "login") {
        // toast.error("WebSocket not ready", {
        //   containerId: POPOVER.NOTIFICATION,
        //   toastId: TOAST_ID.WEBSOCKET_NOT_READEY,
        //   updateId: TOAST_ID.WEBSOCKET_NOT_READEY
        // });
        console.error("WebSocket not ready");
      }
    }

    if (opArgPair && opArgPair.op === "subscribe") {
      this.lastSubscribers = Array.from(new Set([...this.lastSubscribers, ...opArgPair.args]));
    }

    if (opArgPair && opArgPair.op === "unsubscribe") {
      this.lastSubscribers = this.lastSubscribers.filter((p) => !opArgPair.args.includes(p));
    }
  }

  private sendMiddleware(opArgPair: any) {
    if (typeof opArgPair === "string") {
      this.ws?.send(opArgPair);
    } else {
      if (!(this.isAuthentication && this.useThrottle && opArgPair.op === "subscribe")) {
        this.ws?.send(JSON.stringify(opArgPair));
      }
    }
  }

  private heartCheck() {
    this.heartReset();
    this.heartStart();
  }

  private heartStart() {
    if (this.forbidReconnect) return;
    this.pingTimeoutId = window.setTimeout(() => {
      this.send(this.pingMsg);
      this.pongTimeoutId = window.setTimeout(() => {
        this.onclose();
        this.reconnect();
      }, this.pongTimeout);
    }, this.pingTimeout);
  }

  private heartReset() {
    clearTimeout(this.pingTimeoutId);
    clearTimeout(this.pongTimeoutId);
  }

  public onclose() {
    this.heartReset();
    this.ws?.close();
  }
}

export default WS;
