import styled from "@emotion/styled";
import React, { Fragment, useCallback, useState } from "react";
import { memo } from "react";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import { Radio } from "../../../components/Radio";
import useUIKit from "../../../hooks/useUIKit";
import useSafeLayoutEffect from "../../../hooks/useSafeLayoutEffect";
import { DOMAIN } from "../../../config";
import { IAccounts } from "../../../types";
import SwitchAccountLoading from "./SwitchAccountLoading";
import upperFirst from "lodash/upperFirst";
import useLocalization from "../../../hooks/useLocalization";

const ModalWrapper = styled(Modal)`
  .${({ theme }) => theme.prefixCls}-modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  a {
    padding: 0 24px;
  }
`;

const RadioWrapper = styled(Radio)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;
  flex-direction: row-reverse;

  .${({ theme }) => theme.prefixCls}-radio-content {
    margin: 0;
  }

  :hover {
    background: ${({ theme }) => theme.system.blueGray[2]};
  }
  &[data-checked] {
    :hover {
      background: none;
    }
  }
`;

const Container = styled.div`
  max-height: 320px;

  @media screen and (max-width: 767px) {
    max-height: 100%;
  }
  padding-top: 24px;
  overflow-y: auto;
`;

const ButtonWrapper = styled(Button)`
  margin: 24px auto;
  width: 100%;
`;

type TProps = {
  isOpen?: boolean;
  onCancel?: (e: boolean) => void;
  zIndex?: number;
};

const SubAccounts = memo<TProps>(({ isOpen, onCancel, zIndex }) => {
  const { accounts, fetchAccounts, user, onSwitchAccount } = useUIKit();
  const { t } = useLocalization();

  const [isLoading, setLoading] = useState(false);

  const [username, setUserName] = useState<string>();

  useSafeLayoutEffect(() => {
    if (isOpen) {
      setLoading(false);
      fetchAccounts();
    }
  }, [isOpen]);

  const onSwitch = useCallback(
    async (account: IAccounts) => {
      if (onSwitchAccount) {
        setUserName(account.accounts.accountName);
        setLoading(true);
        const success = await onSwitchAccount(account.accounts.accountId);
        setTimeout(() => {
          if (success) {
            onCancel?.(false);
          } else {
            setLoading(false);
          }
        }, 500);
      }
    },
    [onSwitchAccount]
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      onCancel={onCancel}
      renderTitle={!isLoading ? t("Switch Accounts") : null}
      isCancelBtn={!isLoading}
      isMaskCancel={!isLoading}
      zIndex={zIndex}
      isFullScreen={!isLoading}
    >
      {!isLoading ? (
        <Fragment>
          <Container>
            {accounts?.map((p) => (
              <RadioWrapper
                key={p.accounts.accountId}
                checked={user?.accountId === p.accounts.accountId}
                // disabled={user?.accountId === p.accounts.accountId}
                onChange={onSwitch.bind(null, p)}
              >
                {p.accounts.isMainAccount ? "Main Account" : p.accounts.accountName}&nbsp;-&nbsp;
                {upperFirst(p.accounts.tradingType?.toLocaleLowerCase()) || ""}
              </RadioWrapper>
            ))}
          </Container>

          {user?.mainLogin && (
            <a href={DOMAIN?.UI + "/account?type=subaccount"} target="_self" rel="nofollow noopener noreferrer">
              <ButtonWrapper variant="outlined" colorScheme="primary">
                {t("Manage Sub-Accounts")}
              </ButtonWrapper>
            </a>
          )}
        </Fragment>
      ) : (
        <SwitchAccountLoading name={username} />
      )}
    </ModalWrapper>
  );
});

export default SubAccounts;
