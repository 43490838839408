import React from "react";
import { useContext } from "react";

export type IAddressType = "ETH" | "SOL";

export type IConnectWalletContext = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setIsRegistered: React.Dispatch<React.SetStateAction<boolean>>;
  isRegistered: boolean;
  onSign: (address: string, type: IAddressType) => Promise<void>;
  onSignNonce: (address: string, type: IAddressType, referCode?: string) => Promise<void>;
  solanaAddress: string;
  setSolanaAddress: React.Dispatch<React.SetStateAction<string>>;
};

export const ConnectWalletContext = React.createContext<IConnectWalletContext>({
  loading: true,
  setLoading: () => {},
  setIsRegistered: () => {},
  isRegistered: true,
  onSign: async () => {},
  onSignNonce: async () => {},
  solanaAddress: "",
  setSolanaAddress: () => {}
});

export const useConnectWalletModal = () => useContext(ConnectWalletContext);
