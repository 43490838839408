import { POPOVER, toast } from "@oxfun/uikit";

const createError = (error: Error) => {
  const message = error.message.toLowerCase();
  if (message.includes("user rejected") || message.includes("user cancelled")) {
    return;
  }
  toast.error(error.message, {
    containerId: POPOVER.NOTIFICATION
  });
};

export default createError;
