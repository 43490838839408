import styled from "@emotion/styled";
import React, { useCallback } from "react";
import { memo } from "react";
import { Modal } from "../../../../components/Modal";
import useUIKit from "../../../../hooks/useUIKit";
import { LANGUAGES } from "../../../../types/enum";
import { ILocale } from "../../../../types";
import useLocalization from "../../../../hooks/useLocalization";
import { Skeleton } from "../../../../components/Skeleton";
import { useCountDown, useRequest } from "ahooks";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { checkRewards, getRewards } from "../../../../services";
import CongratulationsIcon from "../svgs/CongratulationsIcon";
import { Button } from "../../../../components/Button";
import { zeropad } from "@oxfun/sdk";
import GiftIcon from "../svgs/Gift";
import { toast } from "react-toastify";
import { POPOVER } from "../../../../components/Toast";

dayjs.extend(utc);

const Wrapper = styled.div`
  padding-top: 16px;
`;

const No = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > span {
    :nth-of-type(1) {
      font-size: 28px;
      font-weight: 700;
      color: ${({ theme }) => theme.system.primary[5]};
      padding-top: 8px;
    }

    :nth-of-type(2) {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: ${({ theme }) => theme.system.gray[6]};
      padding: 32px 0px 8px;
    }
  }
  & > div {
    display: grid;
    gap: 4px;
    grid-auto-flow: column;
    width: fit-content;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    & > div {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      background: ${({ theme }) => theme.system.gray[1]};
    }
  }
`;
const Can = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > span {
    :nth-of-type(1) {
      padding-top: 8px;
      font-size: 12px;
      font-weight: 400;
    }

    :nth-of-type(2) {
      font-size: 28px;
      font-weight: 700;
      color: ${({ theme }) => theme.system.primary[5]};
      padding-bottom: 32px;
    }

    :nth-of-type(3) {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: ${({ theme }) => theme.system.gray[6]};
      padding-top: 12px;
    }
  }
`;

const Container = memo(() => {
  const { t } = useLocalization();
  const { user, setRewardsModal } = useUIKit();
  const [_, formattedRes] = useCountDown({
    targetDate: dayjs.utc().startOf("date").add(1, "day")
  });

  const { hours, minutes, seconds } = formattedRes;

  const check = useRequest(checkRewards, {
    ready: !!user?.token
  });

  const get = useRequest(getRewards, {
    ready: !!user?.token,
    manual: true,
    onSuccess: (e) => {
      if (e.success) {
        setRewardsModal(false);
        toast.success("Successful!", {
          containerId: POPOVER.MESSAGE
        });
      }
    }
  });

  return (
    <Wrapper>
      <Skeleton loading={check.loading} animation>
        {!check.data?.data ? (
          <Can>
            <CongratulationsIcon />
            <span>{t("Congratulations you got")}</span>
            <span>{t("$1 worth of OX Credits")}</span>
            <Button
              colorScheme="primary"
              size="small"
              block
              loading={get.loading}
              onClick={() => {
                get.run();
              }}
            >
              {t("Get Rewards")}
            </Button>
            <span>
              {t("Next reward:")}&nbsp;{zeropad(hours, 2)}:{zeropad(minutes, 2)}:{zeropad(seconds, 2)}
            </span>
          </Can>
        ) : (
          <No>
            <GiftIcon />
            <span>{t("$1 worth of OX Credits")}</span>
            <span>{t("Next reward:")}</span>
            <div>
              <div>{zeropad(hours, 2)}</div>
              <span>:</span>
              <div>{zeropad(minutes, 2)}</div>
              <span>:</span>
              <div>{zeropad(seconds, 2)}</div>
            </div>
          </No>
        )}
      </Skeleton>
    </Wrapper>
  );
});

const RewardsModal = memo(() => {
  const { rewardsModal, setRewardsModal } = useUIKit();

  return (
    <Modal isOpen={rewardsModal} renderTitle={null} onCancel={setRewardsModal} width={400} zIndex={1001} destroyOnClose>
      <Container />
    </Modal>
  );
});

export default RewardsModal;
