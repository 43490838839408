import styled from "@emotion/styled";
import { Button, Checkbox, ExternalLink, Input, POPOVER, toast, useLocalization } from "@oxfun/uikit";
import { memo, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useConnectWalletModal } from "./context";

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;

  & > h1 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  & > p {
    font-weight: 500;
    margin-bottom: 8px;
    :first-of-type {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
`;

const Desc = styled.div`
  color: ${({ theme }) => theme.system.gray[6]};
`;

const Action = styled.div`
  padding-top: 42px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .oxfun-checkbox-content {
    font-size: 12px;
  }
`;

const DIV = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.system.blueGray[5]};
  margin: 24px 0;
`;

const TermsAndCondition = memo(() => {
  const { t } = useLocalization();
  const { address } = useAccount();
  const [checked, setChecked] = useState(false);

  const [code, setCode] = useState<string>();

  const { loading, onSignNonce, solanaAddress } = useConnectWalletModal();

  useEffect(() => {
    const shareAccountId = new URL(window.location.href).searchParams.get("shareAccountId");
    !!shareAccountId && setCode(shareAccountId);
  }, []);
  return (
    <Wrapper>
      <p>{t("Referral code")}</p>
      <Input
        placeholder={t("Optional")}
        size="large"
        value={code}
        onChange={(e) => {
          setCode(e.target.value);
        }}
      />

      <DIV />
      <p>{t("Prohibited Countries")}</p>
      <Desc>
        <span>{t("Individuals from")}</span>&nbsp;
        <ExternalLink
          colorScheme="primary"
          href="https://support.ox.fun/en/articles/8803575-prohibited-locations-and-places-of-residence"
          target="_blank"
        >
          {t("restricted countries")}
        </ExternalLink>
        &nbsp;
        <span>
          {t(
            "are not allowed to hold positions or engage in contracts on OX.FUN (OX.FUN). In the event that a trading participant at OX.FUN has misrepresented their location or residency, the platform reserves the right to shut down their accounts and liquidate any active positions."
          )}
        </span>
      </Desc>

      <Action>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
        >
          {t("I agree to")}&nbsp;
          <ExternalLink
            href="https://support.ox.fun/en/articles/8803626-terms-of-service"
            target="_blank"
            colorScheme="primary"
          >
            {t("OX.FUN’s Terms of Service")}
          </ExternalLink>
        </Checkbox>

        <Button
          colorScheme="primary"
          size="small"
          loading={loading}
          disabled={!checked}
          onClick={() => {
            if (solanaAddress) {
              onSignNonce(solanaAddress, "SOL", code);
              return;
            } else {
              if (address) {
                onSignNonce(address, "ETH");
                return;
              }
            }
            toast.error(t("Please refresh and try again"), {
              containerId: POPOVER.MESSAGE
            });
          }}
        >
          {t("Accept")}
        </Button>
      </Action>
    </Wrapper>
  );
});

export default TermsAndCondition;
