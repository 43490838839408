import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import Offline from "./components/Offline";
import useWebsocket from "../../hooks/useWebsocket";
import pubsub from "pubsub-js";
import { OFFLINE_MODAL_STATUS } from "../../types/enum";
import WS from "../../services/ws";
import useAccount from "../../hooks/useAccount";

const AccountIo = memo(
  forwardRef<WS | undefined>((props, ref) => {
    const { token, setUser } = useAccount();

    const { payload, io } = useWebsocket(true);

    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
      if (payload?.event === "accountLogin") {
        setUser(null);
        setOpen(true);
        pubsub.publish(OFFLINE_MODAL_STATUS.EVENT, OFFLINE_MODAL_STATUS.OPEN);
      }

      // if (payload?.event === "switchAccount") {
      //   if (io) {
      //     io.forbidReconnect = true;
      //     io.onclose();
      //     io.forbidReconnect = false;
      //     io.reconnect();
      //   }
      // }

      if (
        payload?.event === "accountLogin" ||
        payload?.event === "logout" ||
        (payload?.event === "login" && !payload?.success)
      ) {
        if (io?.ws?.readyState === 1) {
          io.forbidReconnect = true;
          io.onclose();
        }
      }
    }, [payload]);

    useEffect(() => {
      if (token && io) {
        if (io?.ws?.readyState === 2 || io?.ws?.readyState === 3) {
          io.forbidReconnect = false;
          io.reconnect();
        } else {
          if (token !== io.token) {
            io.forbidReconnect = true;
            io.onclose();
            io.forbidReconnect = false;
            io.reconnect();
          }
        }
      }
    }, [token]);

    useImperativeHandle(ref, () => io);

    return <Offline isOpen={isOpen} onCancel={setOpen} />;
  })
);

export default AccountIo;
