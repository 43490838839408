import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const ReferralIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="M11.666 11.877v1.741A5.001 5.001 0 0 0 5 18.333H3.333a6.667 6.667 0 0 1 8.333-6.457ZM10 10.833c-2.763 0-5-2.237-5-5 0-2.762 2.237-5 5-5 2.762 0 5 2.238 5 5 0 2.763-2.238 5-5 5Zm0-1.666A3.332 3.332 0 1 0 10 2.5a3.332 3.332 0 1 0 0 6.667Zm5.488 5-1.524-1.524 1.179-1.179L18.678 15l-3.535 3.536-1.18-1.18 1.525-1.523H12.5v-1.666h2.988Z"
      />
    </svg>
  );
};

export default ReferralIcon;
