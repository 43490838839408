import styled from "@emotion/styled";
import { useRequest, useSessionStorageState } from "ahooks";
import React, { memo, useEffect, useState } from "react";
import { ExternalLink } from "../../../../components/ExternalLink";
import { useSafeLayoutEffect } from "../../../../hooks";
import CloseIcon from "../../../../icons/CloseIcon";
import { getBanner } from "../../../../services";
import { IBanner } from "../../../../types";
import pubsub from "pubsub-js";
import { BANNER } from "../../../../types/enum";

const Wrapper = styled(ExternalLink)<{ banner: IBanner }>(({ banner }) => {
  return {
    width: "100%",
    padding: "10px 16px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    background: banner.color,
    position: "relative",

    "&[data-bottom]": {
      position: "absolute",
      top: 0,
      transform: "translateY(64px)",
      zIndex: 19
    },

    "&>p": {
      wordBreak: "break-word",
      color: banner.font,
      fontSize: 16,
      paddingRight: 20
    }
  };
});

const Close = styled.span<{ banner: IBanner }>(({ banner }) => {
  return {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 0 2px 4px",
    fontSize: 20,
    cursor: "pointer",
    color: banner.font,
    opacity: 0.8,
    position: "absolute",
    top: 10,
    right: 16,
    ":hover": {
      opacity: 0.5
    }
  };
});

const BannerItem = memo<{ banner: IBanner }>(({ banner }) => {
  const [hide, setHide] = useSessionStorageState(`hide_banner_${banner.bannerId}`, {
    defaultValue: false
  });

  return !hide ? (
    <Wrapper
      key={banner.bannerId}
      banner={banner}
      href={banner.link}
      data-bottom={banner.placement === "bottom" ? "" : undefined}
    >
      <p>{banner.content}</p>
      <Close
        banner={banner}
        onClick={(e) => {
          e.preventDefault();
          setHide(true);
        }}
      >
        <CloseIcon />
      </Close>
    </Wrapper>
  ) : null;
});

const Banner: React.FC = () => {
  const { data, run } = useRequest(getBanner, {
    manual: true
  });

  useEffect(() => {
    pubsub.subscribe(BANNER.EVENT, (target, value) => {
      if (value === BANNER.REQUEST) {
        run({ url: window.location.href });
      }
    });
    return () => {
      pubsub.unsubscribe(BANNER.EVENT);
    };
  }, []);

  useSafeLayoutEffect(() => {
    run({ url: window.location.href });
  }, []);

  return data?.data?.length ? (
    <>
      {data?.data?.map((p) => (
        <BannerItem key={p.bannerId} banner={p} />
      ))}
    </>
  ) : (
    <></>
  );
};

export default Banner;
