import zIndices from "./zIndices";
import light from "./light";
import dark from "./dark";
import mog from "./mog";
import { IThemeMode } from "../types";

const getColor = (themeMode: IThemeMode) => {
  switch (themeMode) {
    case "dark":
      return dark;
    case "light":
      return light;
    case "mog":
      return mog;

    default:
      return dark;
  }
};

const defaultTheme = (themeMode: IThemeMode, prefixCls = "oxfun") => ({
  themeMode,
  prefixCls,
  family: "'Roboto', sans-serif",
  system: {
    white: "#FFF",
    black: "#000",
    transparent: "transparent",
    inherit: "inherit",
    mask: "rgba(7, 9, 16, 0.8)",
    ...getColor(themeMode)
  },
  zIndices
});

export type DefaultTheme = ReturnType<typeof defaultTheme>;

export default defaultTheme;
