import styled from "@emotion/styled";
import React, { memo, useState } from "react";
import { useUIKit } from "../../../hooks";
import TwitterIcon from "./svgs/TwitterIcon";
import TelegramIcon from "./svgs/TelegramIcon";
import { ExternalLink } from "../../../components/ExternalLink";
import { DOMAIN } from "../../../config";
import useLocalization from "../../../hooks/useLocalization";
import { ISystem } from "../type";
import { ILocale } from "../../../types";
import YoutubeIcon from "./svgs/YoutubeIcon";
import DiscordIcon from "./svgs/DiscordIcon";
import { ChevronRightIcon, I18nIcon, SelectDownIcon, SystemLogo } from "../../../svgs";
import { LANGUAGES } from "../../../types/enum";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.system.blueGray[2]};
  border-top: 1px solid ${({ theme }) => theme.system.gray[3]};
`;

const Container = styled.div`
  max-width: 1232px;
  padding: 80px 16px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 16px;
  }
`;

const LeftNode = styled.div`
  & > svg {
    margin: 0 auto;
  }
  .oxfun-external-link {
    color: ${({ theme }) => theme.system.gray[6]};
    :hover {
      color: ${({ theme }) => theme.system.gray[9]};
    }
  }
`;

const Socials = styled.div`
  width: fit-content;
  display: grid;
  grid-auto-flow: column;
  gap: 32px;
  align-items: center;
  margin: 16px 0;
`;

const I18n = styled.div`
  display: grid;
  width: fit-content;
  padding: 6px 12px;
  grid-auto-flow: column;
  border: 1px solid ${({ theme }) => theme.system.gray[3]};
  align-items: center;
  color: ${({ theme }) => theme.system.gray[5]};
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.system.gray[7]};
    border-color: ${({ theme }) => theme.system.gray[7]};
  }
  & > span {
    margin: 0 4px 0 8px;
  }
`;

const Content = styled.ul`
  display: grid;
  width: fit-content;
  gap: 88px;
  grid-auto-flow: column;

  @media screen and (max-width: 1024px) {
    gap: 56px;
  }

  @media screen and (max-width: 768px) {
    grid-auto-flow: row;
    margin-top: 24px;
    gap: 0;
    width: 100%;
  }
`;

const SOCIAL_CONTACT: { icon: React.ReactNode; path: Record<ILocale, string> }[] = [
  {
    icon: <TwitterIcon />,
    path: {
      en: "https://twitter.com/OXFUNHQ",
      "zh-CN": "https://twitter.com/oxfun_cn",
      ko: "https://twitter.com/OXFUNHQ",
      es: "https://twitter.com/OXFUNHQ",
      fr: "https://twitter.com/OXFUNHQ",
      vi: "https://x.com/oxfun_vn",
      ru: "https://twitter.com/OXFUNHQ",
      pt: "https://twitter.com/OXFUNHQ"
    }
  },
  {
    icon: <TelegramIcon />,
    path: {
      en: "https://t.me/oxfun_official",
      "zh-CN": "https://t.me/oxfuncn",
      ko: "https://t.me/oxfun_official",
      es: "https://t.me/oxfun_official",
      fr: "https://t.me/oxfun_official",
      vi: "https://t.me/oxfunvn",
      ru: "https://t.me/oxfun_official",
      pt: "https://t.me/oxfun_official"
    }
  },
  {
    icon: <YoutubeIcon />,
    path: {
      en: "https://youtube.com/@OX_Media",
      "zh-CN": "https://youtube.com/@OX_Media",
      ko: "https://youtube.com/@OX_Media",
      es: "https://youtube.com/@OX_Media",
      fr: "https://youtube.com/@OX_Media",
      vi: "https://youtube.com/@OX_Media",
      ru: "https://youtube.com/@OX_Media",
      pt: "https://youtube.com/@OX_Media"
    }
  },
  {
    icon: <DiscordIcon />,
    path: {
      en: "http://discord.gg/BDFTZHpjYE",
      "zh-CN": "http://discord.gg/BDFTZHpjYE",
      ko: "http://discord.gg/BDFTZHpjYE",
      es: "http://discord.gg/BDFTZHpjYE",
      fr: "http://discord.gg/BDFTZHpjYE",
      vi: "http://discord.gg/BDFTZHpjYE",
      ru: "http://discord.gg/BDFTZHpjYE",
      pt: "http://discord.gg/BDFTZHpjYE"
    }
  }
];

type ILinks = {
  groupKey: string;
  groupText: string;
  links: {
    key: string;
    text: string;
    path: string;
    target?: string;
  }[];
};

const Footer: React.FC<ISystem["Footer"]> = (props) => {
  const { t } = useLocalization();
  const { locale, setI18nModal } = useUIKit();

  const CONTENT_LINKS: ILinks[] = [
    {
      groupKey: "Trading",
      groupText: t("Trading"),
      links: [
        { key: "Trade", text: t("Trade"), path: DOMAIN?.UI + "/markets" },
        { key: "Strategies", text: t("Strategies"), path: DOMAIN?.UI + "/vaults" },
        { key: "Buy OX", text: t("Buy OX"), path: DOMAIN?.UI + "/buy-ox" }
        // { key: "USDC Perps", text: t("USDC Perps"), path: "https://markets.ox.fun" }
      ]
    },
    // {
    //   groupKey: "Products",
    //   groupText: t("Products"),
    //   links: [
    //     { key: "Buy OX", text: t("Buy OX"), path: DOMAIN?.UI + "/buy-ox" },
    //     // { key: "Yield", text: t("Yield"), path: DOMAIN?.UI + "/yield" },
    //     // { key: "OX Bridge", text: t("OX Bridge"), path: DOMAIN?.UI + "/ox-bridge" },
    //     // { key: "OX Chain", text: t("OX Chain"), path: DOMAIN?.UI + "/chain" }
    //   ]
    // },
    {
      groupKey: "Resources",
      groupText: t("Resources"),
      links: [
        // { key: "Whitepaper", text: t("Whitepaper"), path: DOMAIN?.UI + "/whitepaper" },
        {
          key: "Analytics",
          text: t("Analytics"),
          path: "https://analytics.ox.fun"
        },
        { key: "Docs & Info", text: t("Docs & Info"), path: "https://support.ox.fun", target: "_blank" },
        {
          key: "Fees",
          text: t("Fees"),
          path: "https://support.ox.fun/en/articles/8819866-trading-fees",
          target: "_blank"
        }
        // { key: "Learn", text: t("Learn"), path: DOMAIN?.UI + "/learn" }
      ]
    },
    {
      groupKey: "Community",
      groupText: t("Community"),
      links: [
        { key: "Leaderboards", text: t("Leaderboards"), path: DOMAIN?.UI + "/leaderboards" },

        { key: "Referral", text: t("Referral"), path: DOMAIN?.UI + "/referral" }
      ]
    }
  ];

  return (
    <Wrapper {...props}>
      <Container>
        <LeftNode>
          <SystemLogo />
          <Socials>
            {SOCIAL_CONTACT.map((p, i) => (
              <ExternalLink href={p.path[locale]} key={p.path[locale] + i} target="_blank">
                {p.icon}
              </ExternalLink>
            ))}
          </Socials>
          <I18n onClick={setI18nModal.bind(null, true)}>
            <I18nIcon />
            <span>{LANGUAGES[locale]}</span>
            <ChevronRightIcon />
          </I18n>
        </LeftNode>
        <Content>
          {CONTENT_LINKS.map((r) => (
            <Item key={r.groupKey} {...r} />
          ))}
        </Content>
      </Container>
    </Wrapper>
  );
};

const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 12px;
    & > svg {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    max-height: 56px;
    overflow: hidden;
    &[data-open] {
      max-height: 1000px;
      & > p > svg {
        transform: rotate(180deg);
      }
    }
    & > p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
      cursor: pointer;
      min-height: 56px;
      & > svg {
        display: block;
        color: ${({ theme }) => theme.system.gray[6]};
      }
    }
  }
`;

const Links = styled.div`
  display: grid;
  gap: 8px;

  .oxfun-external-link {
    color: ${({ theme }) => theme.system.gray[6]};
    cursor: pointer;
    :hover {
      color: ${({ theme }) => theme.system.gray[9]};
    }
  }

  @media screen and (max-width: 768px) {
    gap: 0;
    .oxfun-external-link {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
`;

const Item = memo<ILinks>(({ links, groupText }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <WrapperItem
      data-open={isOpen ? "" : undefined}
      onClick={() => {
        setOpen(!isOpen);
      }}
    >
      <p>
        <span>{groupText}</span>
        <SelectDownIcon />
      </p>
      <Links>
        {links.map((r) => (
          <ExternalLink key={r.key} href={r.path} target={r.target}>
            {r.text}
          </ExternalLink>
        ))}
      </Links>
    </WrapperItem>
  );
});

export default Footer;
