import * as React from "react";
import { SVGProps } from "react";

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" {...props}>
      <path d="M18.274 1.904h3.373l-7.37 8.423 8.67 11.463H16.16l-5.318-6.952-6.084 6.952H1.382l7.883-9.01L.947 1.904h6.961l4.807 6.354 5.559-6.354ZM17.09 19.77h1.869L6.893 3.817H4.887L17.09 19.77Z" />
    </svg>
  );
};
export default TwitterIcon;
