import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const BalanceIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="M15 5.833h2.5a.833.833 0 0 1 .834.834v10a.833.833 0 0 1-.834.833h-15a.833.833 0 0 1-.833-.833V3.333A.833.833 0 0 1 2.5 2.5H15v3.333ZM3.334 7.5v8.333h13.333V7.5H3.334Zm0-3.333v1.666h10V4.167h-10Zm9.166 6.666H15V12.5h-2.5v-1.667Z"
      />
    </svg>
  );
};

export default BalanceIcon;
