import * as React from "react";
import { SVGProps } from "react";
import { useTheme } from "../../hooks";
const NO_NETWORK = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={88} height={88} fill="none" {...props}>
      <path
        fill={theme.system.blueGray[6]}
        fillRule="evenodd"
        d="M4 20a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2H6v-1a1 1 0 1 0-2 0v1H3a1 1 0 1 0 0 2h1v1Z"
        clipRule="evenodd"
        opacity={0.6}
      />
      <path
        fill={theme.system.blueGray[6]}
        fillRule="evenodd"
        d="M43.5 71C58.136 71 70 59.136 70 44.5S58.136 18 43.5 18 17 29.864 17 44.5 28.864 71 43.5 71Zm-16-17a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM46 66a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm10-8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        clipRule="evenodd"
        opacity={0.3}
      />
      <path
        fill={theme.system.blueGray[6]}
        fillRule="evenodd"
        d="M71.9 72.228c-1.697-2.872-4.493-6.687-8.137-11.09l1.54-1.276c3.672 4.438 6.544 8.345 8.32 11.349.883 1.495 1.525 2.816 1.845 3.895.16.538.256 1.07.233 1.563-.024.499-.176 1.031-.588 1.444-.658.658-1.6.657-2.366.513-.816-.155-1.776-.533-2.832-1.065-2.126-1.071-4.865-2.882-8.018-5.263-6.317-4.77-14.428-11.93-22.847-20.348-8.418-8.419-15.578-16.53-20.348-22.847-2.38-3.153-4.192-5.892-5.263-8.018-.532-1.056-.91-2.017-1.065-2.832-.145-.766-.145-1.708.513-2.366.707-.706 1.738-.658 2.563-.472.894.202 1.96.656 3.147 1.29 2.386 1.275 5.474 3.395 9.018 6.158l-1.23 1.577c-3.51-2.736-6.493-4.776-8.73-5.97-1.126-.602-2.009-.96-2.645-1.104-.431-.097-.628-.072-.699-.054-.015.065-.036.229.029.57.103.546.387 1.313.885 2.303.991 1.967 2.719 4.595 5.073 7.712 4.699 6.223 11.792 14.264 20.166 22.639C48.84 58.91 56.88 66.002 63.103 70.701c3.117 2.354 5.745 4.082 7.712 5.073.99.498 1.757.782 2.304.885.342.065.506.044.57.028a.68.68 0 0 0 .014-.113c.008-.184-.027-.477-.153-.9-.25-.847-.796-2.003-1.65-3.447Zm1.777 4.498.002-.004a.018.018 0 0 1-.002.004Z"
        clipRule="evenodd"
      />
      <path
        fill={theme.system.blueGray[6]}
        d="M46.662 8.713c-1.9-.21-3.485 1.339-3.477 3.25.008 1.816 1.459 3.28 3.263 3.49 7.113.828 13.13 3.715 18.05 8.661 4.927 4.955 7.8 11.016 8.618 18.183.205 1.795 1.662 3.239 3.469 3.247 1.9.008 3.44-1.569 3.235-3.458-.971-8.948-4.522-16.503-10.652-22.667-6.122-6.155-13.624-9.724-22.506-10.706Z"
      />
      <path
        fill={theme.system.blueGray[6]}
        d="M46.544 22.167c-1.841-.258-3.373 1.267-3.365 3.127.008 1.789 1.447 3.21 3.204 3.544 3.393.645 6.297 2.2 8.712 4.669 2.397 2.45 3.915 5.339 4.555 8.666.341 1.775 1.778 3.228 3.585 3.236 1.899.008 3.447-1.571 3.157-3.448-.802-5.183-3.012-9.593-6.628-13.228-3.63-3.651-8.037-5.84-13.22-6.566ZM44.401 44.15a4.186 4.186 0 1 0 5.92-5.92 4.186 4.186 0 0 0-5.92 5.92Z"
      />
      <circle cx={79} cy={28} r={2} fill={theme.system.blueGray[6]} opacity={0.6} />
      <path
        fill={theme.system.blueGray[6]}
        fillRule="evenodd"
        d="M29 75a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        clipRule="evenodd"
        opacity={0.6}
      />
    </svg>
  );
};
export default NO_NETWORK;
