import * as React from "react";
import { SVGProps } from "react";
const DefaultIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
    <g opacity={0.7} clipPath="url(#clip0_548_10787)">
      <path
        opacity={0.2}
        d="M4.68674 27.3139C-1.56216 21.065 -1.56216 10.9341 4.68497 4.68702C10.9332 -1.56117 21.064 -1.56117 27.3129 4.68772C33.5604 10.9352 33.5604 21.0661 27.3122 27.3143C21.0651 33.5614 10.9342 33.5614 4.68674 27.3139Z"
        fill="#5B6A81"
      />
      <path
        opacity={0.2}
        d="M4.68533 27.3125C10.9342 33.5614 21.0651 33.5614 27.3122 27.3143C33.5604 21.0661 33.5604 10.9352 27.3115 4.68632"
        fill="#5B6A81"
      />
      <path
        opacity={0.2}
        d="M4.68497 4.68702C10.9332 -1.56117 21.064 -1.56117 27.3129 4.68772C33.5604 10.9352 33.5604 21.0661 27.3122 27.3143"
        fill="#5B6A81"
      />
      <path
        opacity={0.2}
        d="M4.68566 4.68633C6.96472 2.40727 13.8768 5.62602 20.1257 11.8749C26.3732 18.1224 29.592 25.0345 27.3129 27.3136C25.0349 29.5916 18.1214 26.3742 11.8739 20.1268C5.625 13.8779 2.40766 6.96433 4.68566 4.68633V4.68633Z"
        fill="#5B6A81"
      />
      <path
        opacity={0.2}
        d="M27.3122 4.68701L27.3115 4.6863L15.9979 15.9999L27.3122 27.3143C28.8268 25.8035 30.0219 24.0035 30.8265 22.0213C33.1699 16.2437 31.9985 9.37328 27.3122 4.68701Z"
        fill="#5B6A81"
      />
      <path
        opacity={0.2}
        d="M11.8739 20.1268C18.1214 26.3742 25.0349 29.5916 27.3129 27.3136L27.3136 27.3129C29.5913 25.0338 26.3732 18.1224 20.1257 11.8749L20.1243 11.8735L11.8725 20.1253L11.8739 20.1268Z"
        fill="#5B6A81"
      />
    </g>
    <defs>
      <clipPath id="clip0_548_10787">
        <rect width={32} height={32} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default DefaultIcon;
