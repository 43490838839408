import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="M3.333 15H5v1.667h10V3.333H5V5H3.333V2.5a.833.833 0 0 1 .833-.833h11.667a.833.833 0 0 1 .833.833v15a.834.834 0 0 1-.833.833H4.166a.833.833 0 0 1-.833-.833V15ZM5 9.167h5.833v1.666H5v2.5L.833 10 5 6.667v2.5Z"
      />
    </svg>
  );
};

export default LogoutIcon;
