import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const ClaimRewardIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill={theme.system.primary[5]}
        d="M9.16 2.858c-.18-.124-.68 0-.808.072-1.08.633-2.834 2.179-2.562 3.786.304 1.795 6.491 3.5 6.491 3.5l.822-.41c-.257-1.381-2.678-6.097-3.943-6.948Zm-.945 3.75c-.024-.443.538-.948.85-1.194.117-.092.265-.078.374.044.348.41 1.096 1.445 1.18 2.847 0 0-2.366-1.09-2.404-1.697Z"
      />
      <path
        fill={theme.system.primary[5]}
        d="M16.888 2.963c-.123-.077-.623-.208-.805-.09-1.283.83-3.793 5.498-4.07 6.873l.815.427s6.212-1.59 6.555-3.38c.3-1.602-1.425-3.179-2.495-3.83Zm-2.365 5.33c.109-1.402.879-2.423 1.232-2.826a.261.261 0 0 1 .375-.037c.307.252.862.768.828 1.21-.046.606-2.435 1.653-2.435 1.653Zm-2.54 6.23.006-5.08-7.063-.015.55 5.082 6.506.013Z"
      />
      <path
        fill={theme.system.primary[5]}
        d="m19.534 14.534.709-5.077-7.067-.013-.007 5.08 6.365.01Zm-6.368 1.177-.01 5.078 5.5.01.71-5.078-6.2-.01Zm-1.186-.003-6.376-.013.553 5.082 5.813.01.01-5.079Z"
      />
    </svg>
  );
};

export default ClaimRewardIcon;
