import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const SubAccountIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="M1.667 18.333a6.667 6.667 0 1 1 13.333 0h-1.666a5 5 0 0 0-10 0H1.667Zm6.667-7.5c-2.763 0-5-2.237-5-5 0-2.762 2.237-5 5-5 2.762 0 5 2.238 5 5 0 2.763-2.238 5-5 5Zm0-1.666a3.332 3.332 0 1 0 0-6.667 3.332 3.332 0 1 0 0 6.667Zm6.903 3.085a6.668 6.668 0 0 1 3.93 6.081H17.5a5 5 0 0 0-2.947-4.56l.683-1.52h.001Zm-.573-9.408a4.584 4.584 0 0 1-1.33 8.804V9.971a2.917 2.917 0 0 0 .867-5.508l.463-1.619Z"
      />
    </svg>
  );
};

export default SubAccountIcon;
