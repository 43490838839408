import { toast } from "react-toastify";
import { POPOVER, TOAST_ID } from "../components/Toast";
import { getRecaptchaId } from "../services/requests";

export const onRecaptchaVerify = async (onSuccess?: (token: string) => void, onError?: (err: unknown) => void) => {
  try {
    let _key = "";
    const res = await getRecaptchaId();
    if (res.success) {
      _key = res.data;
    }
    await window.initGeetest4(
      {
        captchaId: _key,
        product: "bind",
        language: "en",
        timeout: 30000
      },
      (captchaObj: any) => {
        captchaObj
          .onNextReady(function () {
            captchaObj.showCaptcha();
          })
          .onSuccess(function () {
            const result = captchaObj.getValidate();
            const base64String = window.btoa(JSON.stringify(result));
            onSuccess?.(base64String);
          })
          .onError(function () {
            toast.error("Captcha failed to load,please try again", {
              containerId: POPOVER.MESSAGE,
              updateId: TOAST_ID.RECAPTCHA_ERROR,
              toastId: TOAST_ID.RECAPTCHA_ERROR
            });
            onError?.("Captcha failed to load,please try again");
          });
      }
    );
  } catch (err: any) {
    toast.error(err, {
      containerId: POPOVER.MESSAGE,
      updateId: TOAST_ID.RECAPTCHA_ERROR,
      toastId: TOAST_ID.RECAPTCHA_ERROR
    });
    onError?.(err);
  }
};
