import * as React from "react";
import { SVGProps } from "react";
const UploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path d="M214.869 344.678a341.504 341.504 0 0 1 628.736 84.48 213.402 213.402 0 0 1-32.512 424.192h-554.65a256 256 0 0 1-41.574-508.672zM512.443 256a256 256 0 0 0-231.577 146.688 42.65 42.65 0 0 1-35.738 24.371A170.7 170.7 0 0 0 256.443 768h554.65a128 128 0 1 0 0-256h-2.253a42.65 42.65 0 0 1-43.008-36.608A256.102 256.102 0 0 0 512.443 256zm-42.65 250.368-61.695 41.114a42.65 42.65 0 0 1-47.309-70.964l128-85.35a42.65 42.65 0 0 1 47.309 0l128 85.35a42.65 42.65 0 1 1-47.309 70.964l-61.696-41.114V682.65a42.65 42.65 0 0 1-85.3 0V506.368z" />
  </svg>
);
export default UploadIcon;
