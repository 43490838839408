import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const HistoryIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="M13.333 1.667 17.5 5.833v11.661c0 .463-.37.84-.828.84H3.327a.833.833 0 0 1-.827-.827V2.493a.83.83 0 0 1 .833-.826h10ZM12.5 3.333H4.167v13.334h11.666v-10H12.5V3.333ZM10.833 7.5v3.333h2.5V12.5H9.167v-5h1.666Z"
      />
    </svg>
  );
};

export default HistoryIcon;
