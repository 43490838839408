import * as React from "react";

const TransferIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M14.8 1.541a.833.833 0 0 0-1.266 1.085l1.32 1.54H6.297a3.9 3.9 0 0 0-2.665 1.04A3.526 3.526 0 0 0 2.5 7.778v1.389a.833.833 0 0 0 1.667 0v-1.39c0-.497.21-.986.604-1.355a2.233 2.233 0 0 1 1.525-.589h8.559l-1.321 1.541a.833.833 0 0 0 1.265 1.085l2.498-2.913a.83.83 0 0 0-.002-1.094l-2.496-2.911Zm-7.506 9.994a.833.833 0 0 1-.079 1.175l-1.664 1.457h8.153c.581 0 1.129-.217 1.525-.588a1.86 1.86 0 0 0 .604-1.357v-1.389a.833.833 0 0 1 1.667 0v1.39c0 .975-.414 1.899-1.131 2.571a3.9 3.9 0 0 1-2.665 1.04H5.55l1.664 1.456a.833.833 0 0 1-1.097 1.254l-3.332-2.915A.84.84 0 0 1 2.5 15c0-.252.112-.478.29-.631l3.328-2.913a.833.833 0 0 1 1.176.079Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TransferIcon;
