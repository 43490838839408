import Cookies from "./Cookies/Cookies";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const System = {
  Header: Header,
  Footer: Footer,
  Cookies: Cookies
};

export { System };
