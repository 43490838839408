import {
  Modal,
  POPOVER,
  getAddressNonce,
  getSolanaAuth,
  getSolanaNonce,
  getWalletAuth,
  isBrowser,
  toast,
  useAccount,
  useLocalization,
  useRecaptcha,
  useTheme,
  useUIKit,
  validateAddress
} from "@oxfun/uikit";
import { memo, useEffect, useMemo, useState } from "react";
import { WagmiProvider, createConfig, http, useSignMessage } from "wagmi";
import ConnectWalletSelectors from "./ConnectWalletSelectors";
import TermsAndCondition from "./TermsAndCondition";
import { ConnectWalletContext, IAddressType } from "./context";
import { injected, walletConnect } from "wagmi/connectors";
import { mainnet } from "viem/chains";
import { createClient } from "viem";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import signMessage from "../../utils/signMessage";
import { sleep } from "../../utils/sleep";
import { PhantomProvider } from "../../types";
import getProvider from "../../utils/getProvider";
import bs58 from "bs58";

const Container = memo<{ setIsRegistered: (e: boolean) => void; returnLink?: string }>((props) => {
  const [loading, setLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(true);
  const { signMessageAsync } = useSignMessage();
  const { setUser } = useUIKit();
  const { onRecaptchaVerify } = useRecaptcha();
  const [solanaAddress, setSolanaAddress] = useState("");
  const [provider, setProvider] = useState<PhantomProvider | undefined>(undefined);

  useEffect(() => {
    props.setIsRegistered(isRegistered);
  }, [isRegistered]);

  useEffect(() => {
    (async () => {
      await sleep(100);
      setProvider(getProvider());
    })();
  }, []);

  const onSignNonce = async (address: string, addressType: IAddressType, referCode?: string) => {
    try {
      setLoading(true);
      let signature: any = "";
      if (addressType === "SOL") {
        const nonce = await getSolanaNonce(address);
        if (!nonce.success) {
          setLoading(false);
          return;
        }
        signature = bs58.encode(((await signMessage(provider as PhantomProvider, nonce.data)) as any).signature);
      } else {
        const nonce = await getAddressNonce(address);
        if (!nonce.success) {
          setLoading(false);
          return;
        }
        signature = await signMessageAsync({ message: nonce.data });
        if (!signature) {
          setLoading(false);
          return;
        }
      }

      onRecaptchaVerify(
        async (token: string) => {
          setLoading(true);
          let auth;
          if (addressType === "SOL") {
            auth = await getSolanaAuth(
              { publicAddress: address, signature: signature, referCode },
              { token: token, deviceType: "web" }
            );
          } else {
            auth = await getWalletAuth(
              { publicAddress: address, signature: signature, referCode },
              { token: token, deviceType: "web" }
            );
          }

          if (auth.success && auth.data.token) {
            setUser(auth.data);
            // const isBlocked = await getIpBlockData();
            // if (isBlocked) {
            //   setLoading(false);
            //   return;
            // }

            if (props.returnLink) {
              window.location.href = props.returnLink;
            }

            setLoading(false);
          }
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    } catch (error) {
      toast.error("Signature failed, Please refresh and try again.", {
        containerId: POPOVER.MESSAGE
      });
      setLoading(false);
    }
  };

  const onSign = async (address: string, addressType: IAddressType) => {
    try {
      setLoading(true);
      const isRegisteredRes = await validateAddress(address);
      if (!isRegisteredRes.success) {
        setLoading(false);
        return;
      }
      if (isRegisteredRes.success && !isRegisteredRes.data) {
        setIsRegistered(false);
        setLoading(false);
        return;
      }
      onSignNonce(address, addressType, undefined);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <ConnectWalletContext.Provider
      value={{
        loading,
        setLoading,
        isRegistered,
        setIsRegistered,
        onSign,
        onSignNonce,
        setSolanaAddress,
        solanaAddress
      }}
    >
      {isRegistered !== false && <ConnectWalletSelectors />}
      {isRegistered === false && <TermsAndCondition />}
    </ConnectWalletContext.Provider>
  );
});

const queryClient = new QueryClient();

const ConnectWalletModal = memo<{ isPage?: boolean; returnLink?: string }>(({ isPage, returnLink }) => {
  const { t } = useLocalization();

  const { isLogin } = useAccount();

  const { isConnectWalletModal, setConnectWalletModal } = useUIKit();
  const [isRegistered, setIsRegistered] = useState<boolean>(true);
  const theme = useTheme();

  const config = useMemo(
    () =>
      createConfig({
        chains: [mainnet],
        client({ chain }) {
          return createClient({ chain, transport: http() });
        },
        connectors: isBrowser
          ? [
              injected({
                shimDisconnect: false
              }),
              walletConnect({
                projectId: "839912adc74c77ce88fcfb0e913cd4d3",
                qrModalOptions: {
                  themeMode: theme.themeMode !== "light" ? "dark" : "light",
                  themeVariables: {
                    "--wcm-background-color": theme.system.primary[4],
                    "--wcm-accent-color": theme.system.primary[5],
                    "--wcm-font-family": theme.family,
                    "--wcm-z-index": "99999999"
                  }
                }
              })
            ]
          : []
      }),
    [theme]
  );

  useEffect(() => {
    if (!isLogin) {
      setConnectWalletModal?.(false);
    }
  }, [isLogin]);

  return isPage ? (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Container setIsRegistered={setIsRegistered} returnLink={returnLink} />
      </QueryClientProvider>
    </WagmiProvider>
  ) : (
    <Modal
      isOpen={isConnectWalletModal && !isLogin}
      onCancel={setConnectWalletModal}
      renderTitle={t("Connect wallet")}
      destroyOnClose
      width={isRegistered === false ? 560 : 400}
      zIndex={1001}
    >
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <Container setIsRegistered={setIsRegistered} returnLink={returnLink} />
        </QueryClientProvider>
      </WagmiProvider>
    </Modal>
  );
});

export default ConnectWalletModal;
