import styled from "@emotion/styled";
import React from "react";
import { memo } from "react";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import { OfflineIcon } from "../../../svgs";
import pubsub from "pubsub-js";
import { OFFLINE_MODAL_STATUS } from "../../../types/enum";
import useLocalization from "../../../hooks/useLocalization";

const ModalWrapper = styled(Modal)`
  .${({ theme }) => theme.prefixCls}-modal-body {
    padding: 40px;
    display: grid;
    gap: 24px;
    grid-auto-flow: row;
    line-height: 1.5;
    text-align: center;

    h1 {
      font-size: 24px;
      font-weight: 500;
      color: ${({ theme }) => theme.system.gray[9]};
    }
    p {
      font-size: 16px;
    }

    .${({ theme }) => theme.prefixCls}-btn {
      height: 48px;
    }
  }
`;

const OfflineIconWrapper = styled(OfflineIcon)`
  color: ${({ theme }) => theme.system.primary[6]};
  margin: 0 auto;
`;

type TProps = {
  isOpen?: boolean;
  onCancel?: (e: boolean) => void;
};

const Offline = memo<TProps>(({ isOpen, onCancel }) => {
  const { t } = useLocalization();
  return (
    <ModalWrapper isCancelBtn={false} isMaskCancel={false} isOpen={isOpen} width={424}>
      <OfflineIconWrapper />
      <h1>{t("Account Security Notice")}</h1>
      <p>
        {t(
          `Your account has been logged in on another device. If you did not initiate this activity, we strongly recommend changing your password immediately to ensure the security of your account`
        )}
      </p>
      <Button
        onClick={() => {
          onCancel?.(false);
          pubsub.publish(OFFLINE_MODAL_STATUS.EVENT, OFFLINE_MODAL_STATUS.CLOSE);
        }}
        colorScheme="primary"
      >
        Close
      </Button>
    </ModalWrapper>
  );
});

export default Offline;
