import styled from "@emotion/styled";
import { memo } from "react";
import useLocalization from "../../../../hooks/useLocalization";
import { DownloadIcon } from "../../../../svgs";
import { DOMAIN } from "../../../../config";
import { ExternalLink } from "../../../../components/ExternalLink";
import { useUIKit } from "../../../../hooks";

// const Wrapper = styled(ExternalLink)`
//   height: 100%;
//   display: flex;
//   white-space: nowrap;
//   align-items: center;
//   cursor: pointer;
//   color: ${({ theme }) => theme.system.gray[9]};
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 22px;
//   :hover {
//     color: ${({ theme }) => theme.system.primary[6]};
//   }

//   &[data-drawer] {
//     width: 100%;
//     height: 64px;
//     padding: 0 16px;
//     font-size: 16px;
//     font-weight: 600;
//     & > svg {
//       margin-right: 16px;
//     }
//     :hover {
//       color: inherit;
//       background: ${({ theme }) => theme.system.gray[1]};
//     }
//   }
// `;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.system.gray[9]};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  :hover {
    color: ${({ theme }) => theme.system.primary[6]};
  }

  &[data-drawer] {
    width: 100%;
    height: 64px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 600;
    & > svg {
      margin-right: 16px;
    }
    :hover {
      color: inherit;
      background: ${({ theme }) => theme.system.gray[1]};
    }
  }
`;

const PCWrapper = styled("div")`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.system.primary[6]};
  }
`;

type TProps = { isDrawer?: boolean };

const Download = memo<TProps>(({ isDrawer }) => {
  const { t } = useLocalization();
  const { setQRCodeModal } = useUIKit();

  if (!isDrawer) {
    return (
      <PCWrapper
        onClick={() => {
          setQRCodeModal(true);
        }}
      >
        <DownloadIcon />
      </PCWrapper>
    );
  }
  return (
    // <Wrapper data-drawer={isDrawer ? "" : undefined} href={DOMAIN?.UI + "/download"}>
    //   {t("Download")}
    // </Wrapper>

    <Wrapper
      data-drawer={isDrawer ? "" : undefined}
      onClick={() => {
        setQRCodeModal(true);
      }}
    >
      {t("Download")}
    </Wrapper>
  );
});

export default Download;
