import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const WithdrawIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="M2.5 15.833h15V17.5h-15v-1.667Zm8.333-10.976v9.31H9.167v-9.31l-5.06 5.06L2.93 8.738 10 1.667l7.07 7.07-1.178 1.179-5.059-5.058v-.001Z"
      />
    </svg>
  );
};

export default WithdrawIcon;
