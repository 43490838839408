import styled from "@emotion/styled";
import { memo, useState } from "react";
import { Modal } from "../../../../components/Modal";
import { useLocalization, useUIKit } from "../../../../hooks";
import { useRequest } from "ahooks";
import QRCode from "qrcode.react";
import { DOMAIN } from "../../../../config";
import { Button } from "../../../../components/Button";
import { confirmQRCodeLogin, getQRCode, getQRCodeStatus } from "../../../../services";
import { Loading } from "../../../../components/Loading";

const QRCodeContainer = styled.div`
  title {
    font-size: 14px;
    font-weight: 500;
    padding-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.system.blueGray[4]};
  }

  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    & > span {
      padding: 12px 0 16px;
      color: ${({ theme }) => theme.system.gray[6]};
    }
  }

  .oxfun-loading {
    height: 120px;
  }
`;

const ConfirmWrapper = styled.div`
  title {
    font-size: 14px;
    font-weight: 500;
    padding-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.system.blueGray[4]};
  }
  p {
    margin: 24px 0;
  }

  .oxfun-btn {
    :first-of-type {
      margin-bottom: 12px;
    }
  }
`;

const Content = styled.div``;

const Overlay = styled.div`
  padding: 16px;
  display: grid;
  gap: 12px;

  & > div {
    font-size: 12px;
    text-align: center;
    & > div {
      text-align: center;
      color: ${({ theme }) => theme.system.gray[6]};
      & > span {
        cursor: pointer;
        &[data-actived],
        &:hover {
          color: ${({ theme }) => theme.system.gray[9]};
        }
      }
    }
  }
`;

const Container = memo(() => {
  const { t } = useLocalization();
  const { setQRCodeModal } = useUIKit();
  const [isPolling, setPolling] = useState(false);
  const [isExpire, setExpire] = useState(false);
  const qrcode = useRequest(getQRCode, {
    onSuccess: (e) => {
      if (e.success && e.data) {
        setExpire(false);

        setTimeout(() => {
          setPolling(true);
        }, 1000);
      }
    }
  });

  const qrcodeStatus = useRequest(() => getQRCodeStatus(qrcode.data?.data || ""), {
    ready: !!qrcode.data?.data && isPolling,
    pollingInterval: 1000,
    refreshDeps: [qrcode.data?.data, isPolling],

    onSuccess: (e) => {
      if (!e.success) {
        setPolling(false);
        setExpire(true);
      }
      if (e?.data === "IN_CONFIRMATION") {
        setPolling(false);
      }
    }
  });

  const confirm = useRequest(confirmQRCodeLogin, {
    manual: true,
    ready: qrcodeStatus.data?.data === "IN_CONFIRMATION",
    refreshDeps: [qrcode.data?.data],
    onSuccess: (e) => {
      if (e.success) {
        setQRCodeModal(false);
      }
    }
  });

  const cancel = useRequest(confirmQRCodeLogin, {
    manual: true,
    ready: qrcodeStatus.data?.data === "IN_CONFIRMATION",
    refreshDeps: [qrcode.data?.data]
  });

  if (qrcodeStatus.data?.data === "IN_CONFIRMATION") {
    return (
      <ConfirmWrapper>
        <title>{t("Confirm Login")}</title>
        <p>{t("Account log in from an unknown device. Please confirm that it is you.")}</p>
        <Button
          colorScheme="primary"
          size="small"
          block
          loading={confirm.loading}
          onClick={() => {
            if (qrcode.data?.data) {
              confirm.run(qrcode.data.data, "IDENTIFIED");
            }
          }}
        >
          {t("Authorize")}
        </Button>
        <Button
          size="small"
          block
          loading={cancel.loading}
          onClick={async () => {
            if (qrcode.data?.data) {
              await cancel.runAsync(qrcode.data.data, "EXPIRE");
              setQRCodeModal(false);
            }
          }}
        >
          {t("Cancel")}
        </Button>
      </ConfirmWrapper>
    );
  }

  return (
    <Content>
      <QRCodeContainer>
        <title>{t("Login Mobile App")}</title>
        {qrcode.loading && <Loading loading />}

        {!qrcode.loading && (
          <div>
            <QRCode
              style={{
                padding: 4,
                background: "#FFF",
                margin: "0 auto"
              }}
              value={qrcode.data?.data || ""}
              imageSettings={{
                src: DOMAIN?.CDN + "/static/images/application-180x180.png",
                x: 50,
                y: 50,
                height: 20,
                width: 20,
                excavate: true
              }}
              size={120}
              bgColor="white"
              fgColor="black"
            />
            <span>{t("Scan to Login Mobile App")}</span>
            {isExpire && (
              <Button
                colorScheme="primary"
                size="small"
                onClick={() => {
                  qrcode.run();
                }}
              >
                {t("Regenerate QR code")}
              </Button>
            )}
          </div>
        )}
      </QRCodeContainer>
    </Content>
  );
});

const Wrapper = styled(Modal)`
  .oxfun-modal-cancel {
    height: 78px;
  }
`;

const MobileQRCode = memo(() => {
  const { qrCodeModal, setQRCodeModal, user } = useUIKit();
  const { t } = useLocalization();
  return (
    <Wrapper destroyOnClose renderTitle={t("Download")} isOpen={qrCodeModal} onCancel={setQRCodeModal} zIndex={1001}>
      <Overlay>
        <QRCode
          style={{
            padding: 4,
            background: "#FFF",
            margin: "0 auto"
          }}
          value={DOMAIN?.UI + "/mobile-redirect"}
          imageSettings={{
            src: DOMAIN?.CDN + "/static/images/application-180x180.png",
            x: 50,
            y: 50,
            height: 20,
            width: 20,
            excavate: true
          }}
          size={120}
          bgColor="white"
          fgColor="black"
        />

        <div>
          <span>{t("Scan to download the App")}</span>
          {/* <div>
        <span
          data-actived={key === "ANDROID" ? "" : undefined}
          onClick={() => {
            setKey("ANDROID");
          }}
        >
          Android
        </span>
        &nbsp;/&nbsp;
        <span
          data-actived={key === "IOS" ? "" : undefined}
          onClick={() => {
            setKey("IOS");
          }}
        >
          iOS
        </span>
      </div> */}
        </div>
      </Overlay>
      {!!user?.token && <Container />}
    </Wrapper>
  );
});

export default MobileQRCode;
