import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} viewBox="0 0 256 256" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.593 109.101c-9.851 56.344 27.049 110.104 81.459 122.128.987.215 1.978.413 2.966.597 8.215 1.529 13.23-7.35 8.503-14.191l-17.177-23.825c-31.238-12.383-50.58-45.139-44.6-79.344 5.55-31.74 34.47-49.644 48.236-54.628l39.048 55.024a20.225 20.225 0 0 1 3.064 16.961c-1.257 6.183-5.288 11.148-10.575 13.832-3.291 4.112-3.618 10.038-.417 14.549l1.461 2.06c4.7 6.623 13.921 8.211 20.595 3.546l2.48-1.734c20.11-14.055 24.931-41.628 10.768-61.585L115.703 24c-44.474 5.39-79.515 39.51-90.11 85.101Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M230.407 146.899c9.852-56.344-27.049-110.104-81.459-122.128-.987-.215-1.978-.413-2.965-.597-8.216-1.53-13.231 7.35-8.504 14.191l17.177 23.825c31.238 12.383 50.581 45.139 44.6 79.344-5.549 31.74-34.47 49.644-48.236 54.628l-38.311-53.986c-4.246-4.424-6.398-10.719-5.265-17.202 1.245-7.117 6.141-12.697 12.449-15.171a12.08 12.08 0 0 0 .007-14.007l-1.461-2.06c-4.7-6.623-13.921-8.21-20.594-3.546l-2.481 1.734c-20.11 14.055-24.93 41.628-10.768 61.585L140.297 232c44.474-5.39 79.516-39.511 90.11-85.101Z"
    />
  </svg>
);

export default SvgComponent;
