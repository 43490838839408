import { useTheme } from "@emotion/react";
import * as React from "react";
import { SVGProps } from "react";

const DepositIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={theme.system.gray[9]}
        d="M2.5 15.833h15V17.5h-15v-1.667Zm8.333-4.856 5.06-5.06 1.178 1.178L10 14.167 2.93 7.096l1.177-1.18 5.06 5.059V1.667h1.666v9.31Z"
      />
    </svg>
  );
};

export default DepositIcon;
