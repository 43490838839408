const zIndices = {
  modal: 1000,
  drawer: 1000,
  dropdown: 1050,
  tooltip: 1070,
  picker: 1050,
  loading: 9
};

export default zIndices;
